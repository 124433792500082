import { Button, ButtonProps, ErrorBoundary } from '@vp/swan'
import { ReactElement } from 'react'
import { Maybe, SanityButton } from '../../../../types/gatsby-graphql'
import { SpacingType } from '../../../../types/spacing'
import { SchemaLinkForContent } from '../schema-link-for-content/schema-link-for-content'

export const SchemaButton = ({
  node: config,
}: {
  node?: Maybe<SanityButton>
}) => {
  if (!config) return null
  return (
    <ErrorBoundary>
      <Button
        skin={config.skin as ButtonProps['skin']}
        width={config.width as ButtonProps['width']}
        buttonShape={config.buttonShape as ButtonProps['buttonShape']}
        {...(config.spacing as SpacingType)}
        render={(props: { children?: ReactElement; className?: string }) => (
          <SchemaLinkForContent
            markKey={config._key}
            mark={config.link}
            noIconAddons
            className={props.className}
          >
            {props.children}
          </SchemaLinkForContent>
        )}
      >
        {config.title}
      </Button>
    </ErrorBoundary>
  )
}
