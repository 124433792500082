import { Column, ColumnProps, StyleSpaceWithAuto } from '@vp/swan'
import { PropsWithChildren } from 'react'
import { Maybe, SanityResponsiveWidth } from '../../../../types/gatsby-graphql'
type spanConfigType = Record<
  'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'default',
  ColumnProps['span']
>

type offsetConfigType = Record<
  'sm' | 'md' | 'lg' | 'xl' | 'default',
  ColumnProps['offset']
>
const standardSpanConfig: spanConfigType = {
  default: 12,
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 3,
} as const

const standardOffsetConfig: offsetConfigType = {
  default: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
} as const

export const AutoColumn = ({
  config,
  children,
  verticalSpacing,
}: PropsWithChildren<{
  config?: Maybe<SanityResponsiveWidth>
  verticalSpacing?: string
}>) => {
  let span: spanConfigType = standardSpanConfig
  if (config?.spanPreset === 'custom') {
    span = {
      default: config.spandefault || standardSpanConfig.default,
      xs: config.spanXs || config.spandefault || standardSpanConfig.xs,
      sm: config.spanSm || config.spandefault || standardSpanConfig.sm,
      md: config.spanMd || config.spandefault || standardSpanConfig.md,
      lg: config.spanLg || config.spandefault || standardSpanConfig.lg,
      xl: config.spanXl || config.spandefault || standardSpanConfig.xl,
    } as spanConfigType
  }
  let offset: offsetConfigType = standardOffsetConfig

  if (config?.offsetPreset === 'custom') {
    offset = {
      default: config.offsetdefault || standardOffsetConfig.default,
      sm: config.offsetSm,
      md: config.offsetMd,
      lg: config.offsetLg,
      xl: config.offsetXl,
    } as offsetConfigType
  }
  return (
    <Column
      span={span.default}
      spanXs={span.xs}
      spanSm={span.sm}
      spanMd={span.md}
      spanLg={span.lg}
      spanXl={span.xl}
      offset={offset.default}
      offsetSm={offset.sm}
      offsetMd={offset.md}
      offsetLg={offset.lg}
      offsetXl={offset.xl}
      mb={verticalSpacing as StyleSpaceWithAuto}
    >
      {children}
    </Column>
  )
}
