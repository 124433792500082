import { Button, Icon, SwanCoreStyleProps } from '@vp/swan'

export const BtnReset = ({
  onReset,
  ...props
}: SwanCoreStyleProps & {
  onReset: () => void
}) => (
  <Button buttonShape="round" {...props} title="Reset Code" onClick={onReset}>
    <Icon iconType="reset" />
  </Button>
)
