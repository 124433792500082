import {
  FlexBox,
  ToggleSwitch,
  ToggleSwitchOffLabel,
  ToggleSwitchOnLabel,
  Typography,
} from '@vp/swan'

import { FC } from 'react'
import { useUserPreferencesContext } from '../..'

export const CodePreferences: FC = props => {
  const { updatePreferences, showCodeByDefault } = useUserPreferencesContext()

  const handleChange = activated => {
    updatePreferences({ showCodeByDefault: activated })
  }

  return (
    <FlexBox alignItems="center" gap={'3'}>
      <Typography id="code-preferences-label">Show all code</Typography>
      <ToggleSwitch
        aria-labelledby="code-preferences-label"
        hideText
        onRequestActivatedChange={handleChange}
        activated={showCodeByDefault}
      >
        <ToggleSwitchOnLabel>On</ToggleSwitchOnLabel>
        <ToggleSwitchOffLabel>Off</ToggleSwitchOffLabel>
      </ToggleSwitch>
    </FlexBox>
  )
}
