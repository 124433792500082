import { useCallback, useEffect, useState } from 'react'

type TCopyStatus = 'INITIAL' | 'DONE' | 'ERROR'

export function useCopyToClipboard() {
  const [copyStatus, setCopyStatus] = useState<TCopyStatus>('INITIAL')
  useEffect(() => {
    // Timer to clear copyStatus after 750ms
    const timer = setTimeout(() => {
      if (copyStatus !== 'INITIAL') {
        setCopyStatus('INITIAL')
      }
    }, 750)
    return () => clearTimeout(timer)
  }, [copyStatus])

  const copyToClipboard = useCallback((content: string) => {
    navigator.clipboard
      .writeText(content)
      .then(() => setCopyStatus('DONE'))
      .catch(e => {
        console.error('Could not copy text >>', e)
        setCopyStatus('ERROR')
      })
  }, [])

  return { copyStatus, copyToClipboard }
}
