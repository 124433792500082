import { Button, Icon, SwanCoreStyleProps } from '@vp/swan'
import { useCopyToClipboard } from '../../../hooks/use-copy-to-clipboard.hook'

export const BtnCopy = ({
  content,
  showInitText = true,
  ...props
}: {
  content: string
  showInitText?: boolean
} & SwanCoreStyleProps) => {
  const { copyStatus, copyToClipboard } = useCopyToClipboard()
  const copyContent = () => copyToClipboard(content)
  return (
    <Button {...props} onClick={copyContent} iconPosition="left">
      {!(!showInitText && copyStatus !== 'INITIAL') && (
        <Icon iconType="saveAsCopy" />
      )}
      {showInitText && copyStatus === 'INITIAL' && 'Copy'}
      {copyStatus === 'DONE' && 'Copied'}
      {copyStatus === 'ERROR' && 'ERR! Copying.'}
    </Button>
  )
}
