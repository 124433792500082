import { PropsWithChildren, useEffect, useState } from 'react'
import { Maybe } from '../../../types/gatsby-graphql'
import { ContentAndTocWrapper } from '../toc/content-and-toc-wrapper'
import {
  ContentBlocks,
  ContentProvider,
  ContentToc,
  SchemaContentBlock,
  useContentContext,
} from './schema-content-block'

const ContentLayout = ({
  showToc,
  children,
}: PropsWithChildren<{ showToc?: Maybe<boolean> }>) => {
  const { containsToc } = useContentContext()
  const hasToc = Boolean(showToc && containsToc) // showToc can be undefined. So don't remove Boolean().
  return (
    <ContentAndTocWrapper toc={<ContentToc />} showToc={hasToc}>
      {children}
      <SchemaContentBlock />
    </ContentAndTocWrapper>
  )
}

export const SchemaContent = ({
  showToc,
  contentBlocks,
  allContentLinks,
  activeHeading,
  currentTab,
  children,
}: PropsWithChildren<{
  contentBlocks?: ContentBlocks
  showToc?: Maybe<boolean>
  allContentLinks: any
  activeHeading?: string
  currentTab?: string
}>) => {
  // Below logic is for scrolling to active section within a tab if the page is refreshed
  const [scrollToHeadingStatus, setScrollToHeadingStatus] = useState<
    'PENDING' | 'ACTIVE' | 'DONE'
  >('PENDING')
  useEffect(() => {
    setScrollToHeadingStatus('ACTIVE')
  }, [])
  useEffect(() => {
    if (scrollToHeadingStatus === 'ACTIVE') {
      const elem = document.getElementById(activeHeading)
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView()
        }, 200)
      }
      setScrollToHeadingStatus('DONE')
    }
  }, [activeHeading, scrollToHeadingStatus])

  return (
    <ContentProvider
      contentBlocks={contentBlocks}
      allContentLinks={allContentLinks}
      currentTab={currentTab}
    >
      <ContentLayout showToc={showToc}>{children}</ContentLayout>
    </ContentProvider>
  )
}
