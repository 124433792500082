import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../utils/storage.utils'

type UserPreferencesType = {
  showCodeByDefault: boolean
}

type UserPreferencesContextType = UserPreferencesType & {
  updatePreferences: (newPrefs: Partial<UserPreferencesType>) => void
}

const UserPreferencesContext = createContext<UserPreferencesContextType | null>(
  null,
)

const DEFAULT_PREFERENCE = {
  showCodeByDefault: false,
}

export const UserPreferencesContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [preferences, setPreferences] = useState({ ...DEFAULT_PREFERENCE })

  useEffect(() => {
    const storedPreference = getFromLocalStorage(
      'userPreferences',
      DEFAULT_PREFERENCE,
    )
    setPreferences({ ...storedPreference })
  }, [])

  function updatePreferences(newPrefs: Partial<UserPreferencesType>) {
    setPreferences(existingPrefs => {
      const combinedPreferences = {
        ...existingPrefs,
        ...newPrefs,
      }

      setToLocalStorage({ key: 'userPreferences', value: combinedPreferences })

      return combinedPreferences
    })
  }

  return (
    <UserPreferencesContext.Provider
      value={{
        updatePreferences,
        ...preferences,
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  )
}

export function useUserPreferencesContext() {
  const ctx = useContext(UserPreferencesContext)
  if (!ctx) {
    throw new Error(
      'useUserPreferencesContext should be accessed inside the context of UserPreferencesContext',
    )
  }
  return ctx
}
