import { Button, SwanCoreStyleProps } from '@vp/swan'
import classNames from 'classnames'
import { FiMoon } from 'react-icons/fi'
import { selected } from './btn-dark-mode.module.scss'
import { useCodeContext } from '../code.context'

export const BtnDarkMode = (props: SwanCoreStyleProps) => {
  const { darkMode, setDarkMode, bgc, setBgc } = useCodeContext()
  const toggleDarkMode = () => {
    setDarkMode(u => !u)
  }
  return (
    <Button
      buttonShape="round"
      {...props}
      onClick={toggleDarkMode}
      className={classNames({ [selected]: darkMode })}
      title={darkMode ? 'Standard mode' : 'Dark mode'}
    >
      <FiMoon size="20" />
    </Button>
  )
}
