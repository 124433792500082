export function getDeprecatedString(str: string) {
  const deprecatedIndex = str.indexOf('@deprecated')
  if (deprecatedIndex !== -1) {
    const newlineIndex = str.indexOf('\n', deprecatedIndex)
    const endIndex = newlineIndex === -1 ? str.length : newlineIndex
    return [
      str.substring(endIndex + 1),
      str.substring(deprecatedIndex, endIndex),
    ]
  } else {
    return [str]
  }
}
