import classNames from 'classnames'
import { ReactNode } from 'react'
import { content, contentWrapper } from './content-and-toc-wrapper.module.scss'

export const ContentAndTocWrapper = ({
  children,
  toc,
  showToc = true,
}: {
  children: ReactNode
  toc: ReactNode
  showToc?: boolean
}) => (
  <section className={classNames({ [contentWrapper]: showToc })}>
    {showToc && <>{toc}</>}
    <article className={classNames({ [content]: showToc })}>{children}</article>
  </section>
)
