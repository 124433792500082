// extracted by mini-css-extract-plugin
export var card = "schema-card-module--card--4dad4";
export var cardImageBottom = "schema-card-module--cardImageBottom--a5983";
export var cardImageCenter = "schema-card-module--cardImageCenter--d3474";
export var cardImageLeft = "schema-card-module--cardImageLeft--75c2c";
export var cardImageRight = "schema-card-module--cardImageRight--635ab";
export var cardImageTop = "schema-card-module--cardImageTop--40324";
export var cardListViewNoImage = "schema-card-module--cardListViewNoImage--b12ce";
export var cardTextAlignCenter = "schema-card-module--cardTextAlignCenter--00072";
export var cardTextAlignJustify = "schema-card-module--cardTextAlignJustify--a56d4";
export var cardTextAlignRight = "schema-card-module--cardTextAlignRight--9722b";
export var description = "schema-card-module--description--5bb5c";
export var image = "schema-card-module--image--1aee7";
export var link = "schema-card-module--link--3e1bf";
export var noImage = "schema-card-module--noImage--7f52f";
export var skinDisabled = "schema-card-module--skinDisabled--99ddf";
export var skinDo = "schema-card-module--skinDo--67e93";
export var skinDont = "schema-card-module--skinDont--635de";
export var skinError = "schema-card-module--skinError--198ca";
export var skinInfo = "schema-card-module--skinInfo--6a10a";
export var skinNeutral = "schema-card-module--skinNeutral--25c38";
export var skinSuccess = "schema-card-module--skinSuccess--851cc";
export var skinWarn = "schema-card-module--skinWarn--a075c";
export var title = "schema-card-module--title--32749";
export var titleDescriptionContainer = "schema-card-module--titleDescriptionContainer--5a5a7";