import {
  ErrorBoundary,
  TabContent,
  TabHeader,
  Tabs,
  TabsContents,
  TabsHeaders,
} from '@vp/swan'
import { ReactNode, useState } from 'react'
import { Maybe, SanityTabSection } from '../../../types/gatsby-graphql'
import { SchemaContent } from '../schema-content/content'
import { tabHeadingItem } from './schema-tab-section.component.module.scss'

export type ExtraTab = {
  title: string
  tabId: string
  content: ReactNode
}

export const SchemaTabSection = ({
  node: config,
}: {
  node: Maybe<SanityTabSection>
}) => {
  const tabs = config.items
  const [defaultSelectedTab] = useState(() => {
    return tabs[0]?._key || 'tab-idx-0'
  })
  const [activeTab, setActiveTab] = useState('')

  if (!tabs.length) {
    return null
  }

  return (
    <ErrorBoundary>
      <Tabs
        selectedTabId={activeTab || defaultSelectedTab}
        onRequestTabChange={nextTab => setActiveTab(nextTab)}
        my={{ xs: '5', sm: '7' }}
      >
        <TabsHeaders>
          {tabs?.map((tab, idx) =>
            tab ? (
              <TabHeader
                className={tabHeadingItem}
                key={tab?._key || idx}
                tabId={tab._key || 'tab-idx-' + idx}
              >
                {tab.title}
              </TabHeader>
            ) : null,
          )}
        </TabsHeaders>
        <TabsContents px={{ xs: '5', sm: '7' }}>
          {tabs?.map((tab, idx) => {
            if (!tab || tab.disabled) return null
            const tabId = tab._key || 'tab-idx-' + idx
            return (
              <TabContent key={tab._key || idx} tabId={tabId}>
                <SchemaContent
                  contentBlocks={tab.content}
                  allContentLinks={undefined}
                  currentTab={tabId}
                />
              </TabContent>
            )
          })}
        </TabsContents>
      </Tabs>
    </ErrorBoundary>
  )
}
