import {
  AlertBox,
  AlertBoxDismissButton,
  AlertBoxProps,
  ErrorBoundary,
} from '@vp/swan'
import { Maybe, SanityAlertBox } from '../../../../types/gatsby-graphql'
import { SchemaMarkdown } from '../../schema-markdown/schema-markdown'

export const SchemaAlertBox = ({ node }: { node?: Maybe<SanityAlertBox> }) => {
  const { dismissButton, skin, markdown } = node
  if (!markdown) return null
  return (
    <ErrorBoundary>
      <AlertBox skin={(skin as AlertBoxProps['skin']) ?? 'standard'}>
        <SchemaMarkdown node={markdown} />
        {dismissButton && <AlertBoxDismissButton />}
      </AlertBox>
    </ErrorBoundary>
  )
}
