import { Box, ErrorBoundary, PipeSeparator, Typography } from '@vp/swan'
import { Maybe } from '../../../types/gatsby-graphql'
import { formattedDateTime } from '../../utils/date.utils'

export const SchemaPublishedOn = ({
  publishedOn,
  lastUpdatedOn,
}: {
  publishedOn?: Maybe<string>
  lastUpdatedOn?: Maybe<string>
}) => {
  return (
    <ErrorBoundary>
      <Box my={'3'}>
        {!!publishedOn && (
          <Typography as="span" fontSize="small" textColor="subtle">
            Published: {formattedDateTime(publishedOn, false)}
          </Typography>
        )}
        {!!lastUpdatedOn && (
          <Typography as="span" fontSize="small" textColor="subtle">
            {!!publishedOn && <PipeSeparator mx={'5'} />}
            Last updated: {formattedDateTime(lastUpdatedOn, false)}
          </Typography>
        )}
      </Box>
    </ErrorBoundary>
  )
}
