import { Box, Card, Link, Typography } from '@vp/swan'
import { Heading, SchemaImageBlock } from '..'
import { Maybe, SanityImageBlock } from '../../../types/gatsby-graphql'
import { card, cardImg } from './page-card.module.scss'

type PageCardProps = {
  page: {
    title?: Maybe<string>
    description?: Maybe<string>
    imageBlock?: Maybe<SanityImageBlock>
    slug?: Maybe<{
      current?: Maybe<string>
    }>
    classification?: string[]
  }
  slugPrefix: string
}
export const PageCard = ({ page, slugPrefix }: PageCardProps) => {
  return (
    <Card
      bordered
      evenHeight
      render={props => (
        <Link to={`${slugPrefix}/${page.slug?.current}`} {...props} />
      )}
      className={card}
      p={0}
    >
      <SchemaImageBlock
        node={{ ...page?.imageBlock, objectFit: 'cover' }}
        className={cardImg}
        showCaption={false}
      />
      <Box p={'5'}>
        <Heading element="h4">{page.title}</Heading>
        <Typography textColor="subtle" fontSize="small" mt={'4'}>
          {page.description}
        </Typography>
      </Box>
    </Card>
  )
}
