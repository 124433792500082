import { Box, Button, ErrorBoundary, Icon, P } from '@vp/swan'
import classNames from 'classnames'
import { ReactElement } from 'react'
import { Heading, SchemaImageBlock } from '../..'
import { Maybe, SanityCard, SanityLink } from '../../../../types/gatsby-graphql'
import { SchemaMarkdown } from '../../schema-markdown/schema-markdown'
import { SchemaLinkForContent } from '../schema-link-for-content/schema-link-for-content'
import {
  card,
  cardImageBottom,
  cardImageCenter,
  cardImageLeft,
  cardImageRight,
  cardImageTop,
  cardListViewNoImage,
  cardTextAlignCenter,
  cardTextAlignJustify,
  cardTextAlignRight,
  description,
  image,
  link,
  noImage,
  skinDisabled,
  skinDo,
  skinDont,
  skinError,
  skinInfo,
  skinNeutral,
  skinSuccess,
  skinWarn,
  title,
  titleDescriptionContainer,
} from './schema-card.module.scss'
import { getJSON } from './schema-card.utils'

const components = {
  p: ({ node, ...props }) => <P my={'3'} fontSize="standard" {...props} />,
}

function getCardClassNames(config: Maybe<SanityCard>): string {
  return classNames(card, {
    [cardImageTop]: config?.variant === 'view:image-top',
    [cardImageCenter]: config?.variant === 'view:image-center',
    [cardImageBottom]: config?.variant === 'view:image-bottom',
    [cardImageLeft]: config?.variant === 'view:image-left',
    [cardImageRight]: config?.variant === 'view:image-right',
    [cardListViewNoImage]: config?.variant === 'listView:no-image',
    [skinNeutral]: config?.skin === 'neutral',
    [skinInfo]: config?.skin === 'info',
    [skinDisabled]: config?.skin === 'disabled',
    [skinSuccess]: config?.skin === 'success',
    [skinWarn]: config?.skin === 'warn',
    [skinError]: config?.skin === 'error',
    [skinDo]: config?.skin === 'do',
    [skinDont]: config?.skin === 'dont',
  })
}

function shouldGroupTitleDescription(
  variant?: string,
  title?: ReactElement,
  description?: ReactElement,
) {
  const shouldGroup =
    variant === 'view:image-left' ||
    variant === 'view:image-right' ||
    variant === 'listView:no-image'
  if (shouldGroup) {
    return (
      <div
        className={classNames(titleDescriptionContainer, {
          [noImage]: variant === 'listView:no-image',
        })}
      >
        {title}
        {description}
      </div>
    )
  }
  return (
    <>
      {title}
      {description}
    </>
  )
}
function wrapWithLink(
  link?: SanityLink,
  markKey?: string,
  node?: ReactElement,
  variant?: string,
  isChild = false,
) {
  const forListViewWithNoImage = variant === 'listView:no-image' && isChild
  const forOthers = variant !== 'listView:no-image' && !isChild
  if (!link || !markKey || !(forListViewWithNoImage || forOthers)) {
    return node
  }
  return (
    <SchemaLinkForContent
      markKey={markKey}
      mark={link}
      enforceLink
      noIconAddons
    >
      {node}
    </SchemaLinkForContent>
  )
}

export const SchemaCard = ({
  node: config,
  fallbackKey,
}: {
  node?: Maybe<SanityCard>
  fallbackKey?: string
}) => {
  const _key = `card_${config._key || fallbackKey}`
  const overrideConfig = getJSON(config.jsonValueRaw)
  const childLinkElement = wrapWithLink(
    config.link,
    _key,
    config?.variant === 'listView:no-image' && config.link ? (
      <Button skin="primary">View job description</Button>
    ) : null,
    config.variant,
    true,
  )
  const configTitle = config?.title ? (
    <Heading
      element="h4"
      className={classNames(title, {
        [cardTextAlignRight]: config?.titleAlign === 'right',
        [cardTextAlignCenter]: config?.titleAlign === 'center',
        [cardTextAlignJustify]: config?.titleAlign === 'justify',
      })}
    >
      {config?.skin === 'do' && <Icon size="28p" iconType="check" mr={'2'} />}
      {config?.skin === 'dont' && (
        <Icon size="28p" iconType="error" skin="error" mr={'2'} />
      )}
      {config?.title}
    </Heading>
  ) : null
  const wrappedHeading = wrapWithLink(
    config.link,
    _key,
    configTitle,
    config.variant,
    true,
  )
  const cardElem = (
    <ErrorBoundary>
      <Box
        className={`${getCardClassNames(config)}`}
        {...(overrideConfig.container || {})}
      >
        <SchemaImageBlock className={image} node={config?.imageBlock} />
        {shouldGroupTitleDescription(
          config.variant,
          wrappedHeading,
          <div
            className={classNames(description, {
              [cardTextAlignRight]: config?.textAlign === 'right',
              [cardTextAlignCenter]: config?.textAlign === 'center',
              [cardTextAlignJustify]: config?.textAlign === 'justify',
            })}
          >
            <SchemaMarkdown
              node={config?.description}
              components={components}
            />{' '}
          </div>,
        )}
        {childLinkElement ? (
          <div className={link}>{childLinkElement}</div>
        ) : null}
      </Box>
    </ErrorBoundary>
  )
  return (
    <ErrorBoundary>
      {wrapWithLink(config.link, _key, cardElem, config.variant, false)}
    </ErrorBoundary>
  )
}
