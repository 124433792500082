import { windowExists } from './dom.utils'

export const getFromLocalStorage = <T>(
  key: string,
  defaultValue?: T,
): T | null => {
  try {
    if (windowExists()) {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    }
    return defaultValue
  } catch (error) {
    console.error('Error caught while parsing storage >', error)
    return null
  }
}

export const setToLocalStorage = <T>({
  key,
  value,
}: {
  key: string
  value: T
}) => {
  try {
    if (windowExists()) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  } catch (error) {
    console.error('Error caught while setting to storage:', error)
  }
}

export const removeFromLocalStorage = (key: string) => {
  try {
    if (windowExists()) {
      localStorage.removeItem(key)
    }
  } catch (error) {
    console.error('Error caught while removing from local storage:', error)
  }
}
