import { ErrorBoundary, Table, TableBody, TableCell, TableRow } from '@vp/swan'
import { Maybe, SanityTable } from '../../../../types/gatsby-graphql'
import { SchemaMarkdown } from '../../schema-markdown/schema-markdown'
import { schemaTableCell } from './schema-table.module.scss'

export const SchemaTable = ({ node }: { node?: Maybe<SanityTable> }) => {
  return (
    <ErrorBoundary>
      <Table>
        <TableBody>
          {node?.rows?.map(row =>
            row?.cells?.length ? (
              <TableRow key={row._key}>
                {row?.cells?.map(cell => (
                  <TableCell key={cell} className={schemaTableCell}>
                    <SchemaMarkdown node={cell} />
                  </TableCell>
                ))}
              </TableRow>
            ) : null,
          )}
        </TableBody>
      </Table>
    </ErrorBoundary>
  )
}
