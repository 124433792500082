import {
  SWAN_BASE_URL_MAP,
  SWAN_ICON_KEY_MAP,
  SwanPathTypeEnum,
  getSwanIconUrl,
} from '@vp/swan'
import { Liquid } from 'liquidjs'
import { Language } from 'prism-react-renderer'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const engine = new Liquid({
  globals: {
    SwanPathTypeEnum,
    SWAN_BASE_URL_MAP,
    SWAN_ICON_KEY_MAP,
  },
})

engine.plugin(function (Liquid) {
  this.registerFilter('icon', (iconName, ...args) =>
    getSwanIconUrl(iconName, ...args),
  )
})

type HtmlCodeContextProps = PropsWithChildren<{
  htmlCode: any
}>

const HtmlCodeContext = createContext<{
  htmlCode: any
  setHtmlCode: any
  codeOutput: any
  language: Language
} | null>(null)

export const HtmlCodeProvider = ({
  htmlCode,
  children,
}: HtmlCodeContextProps) => {
  const [codeInput, setCodeInput] = useState(htmlCode?.code || '')
  const [codeOutput, setCodeOutput] = useState('')
  const language = htmlCode?.language as Language

  useEffect(() => {
    engine
      .parseAndRender(codeInput)
      .then(result => {
        setCodeOutput(result)
      })
      .catch(e => {
        console.error('liquid js template error >>', e)
      })
  }, [codeInput])

  const value = useMemo(() => {
    return {
      htmlCode,
      setHtmlCode: setCodeInput,
      codeOutput,
      language,
    }
  }, [htmlCode, codeOutput, language])

  return (
    <HtmlCodeContext.Provider value={value}>
      {children}
    </HtmlCodeContext.Provider>
  )
}

export function useHtmlCodeContext() {
  const ctx = useContext(HtmlCodeContext)
  if (!ctx) {
    throw new Error(
      'useHtmlCodeContext should be accessed inside the context of HtmlCodeContext',
    )
  }
  return ctx
}
