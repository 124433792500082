import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

import {
  Maybe,
  SanityBlock,
  SanityCodePreview,
  SanityGrid,
  SanityImageBlock,
  SanityPropsBlock,
  SanityTable,
} from '../../../../types/gatsby-graphql'
import { getRightMenuToc, getToc } from './generateToc'

export type ContentBlocks = Maybe<
  Array<
    | Maybe<SanityBlock>
    | Maybe<SanityCodePreview>
    | Maybe<SanityGrid>
    | Maybe<SanityImageBlock>
    | Maybe<SanityTable>
    | Maybe<SanityPropsBlock>
  >
>

export type HeadingsObjectValue = { slug: string; text: string }

export type HeadingsRecord = Record<string, HeadingsObjectValue>

export type TocObjectValue = HeadingsObjectValue & {
  subToc?: HeadingsObjectValue[]
}

export type TocValue = Record<string, TocObjectValue>

export type ContentContextValue = {
  currentTab?: string
  contentBlocks?: ContentBlocks
  headingsMap: HeadingsRecord
  tocMap: Record<string, TocObjectValue>
  containsToc: boolean
  allContentLinks: Record<
    string,
    {
      title?: string
      url?: string
      markRefKey: string
    }
  >
}

const SchemaContentContext = createContext<ContentContextValue>({
  headingsMap: {},
  tocMap: {},
  containsToc: false,
  allContentLinks: {},
})

export const ContentProvider = ({
  children,
  contentBlocks,
  allContentLinks,
  currentTab,
}: PropsWithChildren<{
  contentBlocks?: ContentBlocks
  allContentLinks: any
  currentTab?: string
}>) => {
  const value: ContentContextValue = useMemo(() => {
    const tocMap = getRightMenuToc(contentBlocks)
    const headingsMap: HeadingsRecord = {}

    getToc(contentBlocks).forEach((v, k) => {
      headingsMap[k] = v
    })
    return {
      currentTab,
      tocMap,
      headingsMap,
      contentBlocks,
      containsToc: !!Object.keys(tocMap).length,
      allContentLinks,
    }
  }, [contentBlocks, allContentLinks, currentTab])
  return (
    <SchemaContentContext.Provider value={value}>
      {children}
    </SchemaContentContext.Provider>
  )
}

export function useContentContext() {
  return useContext(SchemaContentContext)
}
export function useHeadingId(key: string) {
  const { headingsMap } = useContentContext()
  if (headingsMap[key]) return headingsMap[key].slug
  return undefined
}

export function useLinkDetail(node: { markKey: string }) {
  const { allContentLinks } = useContentContext()
  if (!allContentLinks) return null
  const config = allContentLinks[node.markKey]
  if (config?.url) return config
  return null
}
