import { Maybe, SanityGridItem } from '../../../../types/gatsby-graphql'
import { SchemaImageBlock } from '../../schema-image-block/schema-image-block'
import { SchemaMarkdown } from '../../schema-markdown/schema-markdown'
import { SchemaButton } from '../schema-button/schema-button'
import { SchemaCallout } from '../schema-callout/schema-callout'
import { SchemaCard } from '../schema-card/schema-card'
import { SchemaRow } from '../schema-row/schema-row'
import { SchemaSwanCard } from '../schema-swan-card/schema-swan-card'
import { AutoColumn } from './responsive-column'

export const SchemaGridItem = ({
  config,
  verticalSpacing,
}: {
  config?: Maybe<SanityGridItem>
  verticalSpacing?: string
}) => {
  if (!config?.itemType) return null
  switch (config.itemType) {
    case 'imageBlock':
      return (
        <AutoColumn
          config={config.responsiveWidth}
          verticalSpacing={verticalSpacing}
        >
          <SchemaImageBlock node={config.imageBlock} />
        </AutoColumn>
      )
    case 'swanCard':
      return (
        <AutoColumn
          config={config.responsiveWidth}
          verticalSpacing={verticalSpacing}
        >
          <SchemaSwanCard node={config.swanCard} insideGrid />
        </AutoColumn>
      )
    case 'button':
      return (
        <AutoColumn
          config={config.responsiveWidth}
          verticalSpacing={verticalSpacing}
        >
          <SchemaButton node={config.button} />
        </AutoColumn>
      )
    case 'callout':
      return (
        <AutoColumn
          config={config.responsiveWidth}
          verticalSpacing={verticalSpacing}
        >
          <SchemaCallout node={config.callout} />
        </AutoColumn>
      )
    case 'row':
      return (
        <AutoColumn
          config={config.responsiveWidth}
          verticalSpacing={verticalSpacing}
        >
          <SchemaRow node={config.row} />
        </AutoColumn>
      )
    case 'card':
      return (
        <AutoColumn
          config={config.responsiveWidth}
          verticalSpacing={verticalSpacing}
        >
          <SchemaCard node={config.card} />
        </AutoColumn>
      )

    case 'markdown':
      return (
        <AutoColumn
          config={config.responsiveWidth}
          verticalSpacing={verticalSpacing}
        >
          <SchemaMarkdown node={config.markdown} />
        </AutoColumn>
      )
    default:
      return null
  }
}
