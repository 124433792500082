import {
  Button,
  ButtonProps,
  Icon,
  ModalDialog,
  ModalDialogBody,
  ModalDialogContent,
  Typography,
} from '@vp/swan'
import { FC, useEffect, useState } from 'react'
import { btnSearch, searchContainer } from './btn-search.module.scss'
import { Search } from './search'

export const BtnSearch: FC<Partial<ButtonProps>> = (
  buttonProps: Partial<ButtonProps>,
) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      const targetNodeName = (event.target as Element).nodeName?.toLowerCase()
      if (
        event.key === '/' &&
        !['input', 'textarea'].includes(targetNodeName)
      ) {
        event.preventDefault()
        setIsMenuOpen(true)
      }
    })
  }, [])

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <>
      <Button
        skin={'unstyled'}
        aria-label="Search"
        className={btnSearch}
        onClick={() => setIsMenuOpen(true)}
        {...buttonProps}
      >
        <Icon iconType="search" size="20p" />
        <Typography
          as="span"
          pl={{ xs: '3', sm: 0 }}
          textColor="subtle"
          fontSize="small"
        >
          Search &quot;/&quot;
        </Typography>
      </Button>
      <ModalDialog
        aria-labelledby="search"
        bodyWidth="capped"
        isOpen={isMenuOpen}
        onRequestDismiss={closeMenu}
      >
        <ModalDialogContent className={searchContainer} fullBleed>
          <ModalDialogBody>
            <Search
              key={isMenuOpen ? 'menu-open' : 'menu-close'} // it remount the search component to reset the searched input
              onClick={closeMenu}
            />
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
