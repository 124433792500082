/* eslint-disable import/no-extraneous-dependencies */
import DOMPurify from 'dompurify'
import babelParser from 'prettier/parser-babel'
import htmlParser from 'prettier/parser-html'
import prettier from 'prettier/standalone'

function getParser(lang: string) {
  switch (lang) {
    case 'jsx':
    case 'javascript':
    case 'js':
      return 'babel'
    case 'tsx':
    case 'typescript':
    case 'ts':
      return 'babel-ts'
    case 'markup':
    case 'html':
      return 'html'
    default:
      return null
  }
}

export default function formatCode(
  code: string,
  language: string,
  options: any = {},
): string {
  try {
    const parser = getParser(language)
    if (parser) {
      const formattedCode = prettier
        .format(code, {
          parser,
          plugins: [htmlParser, babelParser],
          semi: false,
          trailingComma: 'all',
          bracketSpacing: true,
          quoteProps: 'as-needed',
          arrowParens: 'avoid',
          jsxSingleQuote: false,
          singleQuote: true,
          ...options,
        })
        .replace(/[\r\n]+$/, '')
      if (formattedCode.startsWith(';')) return formattedCode.substring(1)
      return formattedCode
    }

    return code
  } catch (err) {
    console.error(
      'formatting code issue ------',
      code,
      '\n\n\n-----------',
      err,
    )
    throw Error(err)
  }
}

export function sanitizeCode(code: string) {
  try {
    return DOMPurify.sanitize(code)
  } catch (e) {
    console.error('Error caught while sanitizing code:', e)
  }
}
