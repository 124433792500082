import { Box } from '@vp/swan'
import classNames from 'classnames'
import { sanitizeCode } from '../../../utils/code.utils'
import { CodeEditor } from './code-editor'
import { useHtmlCodeContext } from './code-html.context'
import { useCodeContext } from './code.context'
import { limitedPreview } from './code.module.scss'

export const CodeLivePreviewHtml = () => {
  const { previewWithoutScroll } = useCodeContext()
  const { codeOutput } = useHtmlCodeContext()

  return (
    <Box
      p={5}
      dangerouslySetInnerHTML={{ __html: codeOutput }}
      className={classNames({
        'position-relative': true,
        [limitedPreview]: !previewWithoutScroll,
      })}
    />
  )
}

export const CodeLiveEditorHtml = () => {
  const { htmlCode, setHtmlCode, language } = useHtmlCodeContext()

  return (
    <CodeEditor
      code={htmlCode.code}
      onChange={code => setHtmlCode(sanitizeCode(code))}
      language={language || 'markup'}
      shareWithPreview
    />
  )
}
