import { Box, SwanCoreStyleProps } from '@vp/swan'
import { PropsWithChildren } from 'react'
import { grid } from './grid.module.scss'

type StandardAutoGridProps = SwanCoreStyleProps

export const StandardAutoGrid = ({
  children,
  ...baseProps
}: PropsWithChildren<StandardAutoGridProps>) => (
  <Box {...baseProps} className={grid}>
    {children}
  </Box>
)
