import { Button, Icon, SwanCoreStyleProps } from '@vp/swan'
import { useEffect, useRef, useState } from 'react'
import { compressContent } from '../../../../utils/compress-content'
import { copyToClipboard } from '../../../../utils/copy-to-clipboard.utils'

export const BtnShare = ({
  content,
  language,
  renderMultipleComponents,
  ...props
}: {
  content: string
  language: string
  renderMultipleComponents?: boolean
} & SwanCoreStyleProps) => {
  const [shareUrl, setShareUrl] = useState('')
  const ref = useRef(null)

  useEffect(() => {
    if (content) {
      const url = `/swan/preview/?language=${encodeURIComponent(
        language,
      )}&code=${encodeURIComponent(
        compressContent(content),
      )}&renderMultipleComponents=${renderMultipleComponents}`

      setShareUrl(url)
    }
  }, [content, language, renderMultipleComponents])

  return (
    <Button
      iconPosition="left"
      {...props}
      render={props => (
        <a
          href={shareUrl}
          ref={ref}
          onClick={() => copyToClipboard(ref.current?.href)}
          target="_blank"
          rel="noreferrer"
          className={props.className}
        >
          {props.children}
        </a>
      )}
    >
      <Icon iconType="upload" />
      Share
    </Button>
  )
}
