import { graphql, useStaticQuery } from 'gatsby'
import { PageTitle, SlugPrefix } from '../constants/slug-prefix'
import {
  SanityComponentPageConnection,
  SanityContentPageConnection,
  SanityDocumentationPageConnection,
  SanityFoundationPageConnection,
} from '../gatsby/page-types'

export function useAllPages() {
  const allPages = useStaticQuery<{
    allSanityComponentPage: Partial<SanityComponentPageConnection>
    allSanityContentPage: Partial<SanityContentPageConnection>
    allSanityDocumentationPage: Partial<SanityDocumentationPageConnection>
    allSanityFoundationPage: Partial<SanityFoundationPageConnection>
  }>(graphql`
    query allPages {
      allSanityComponentPage(sort: { title: ASC }) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityContentPage(sort: [{ orderRank: ASC }, { title: ASC }]) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityDocumentationPage(sort: [{ orderRank: ASC }, { title: ASC }]) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityFoundationPage(sort: [{ orderRank: ASC }, { title: ASC }]) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
    }
  `)
  return [
    {
      title: PageTitle.documentationPage,
      nodes: allPages.allSanityDocumentationPage.nodes.map(u => ({
        ...u,
        slug: {
          ...u.slug,
          current: `${SlugPrefix.documentationPage}/${u.slug.current}`,
        },
      })),
    },
    {
      title: PageTitle.foundationPage,
      nodes: allPages.allSanityFoundationPage.nodes.map(u => ({
        ...u,
        slug: {
          ...u.slug,
          current: `${SlugPrefix.foundationPage}/${u.slug.current}`,
        },
      })),
    },
    {
      title: PageTitle.componentPage,
      nodes: allPages.allSanityComponentPage.nodes.map(u => ({
        ...u,
        slug: {
          ...u.slug,
          current: `${SlugPrefix.componentPage}/${u.slug.current}`,
        },
      })),
    },
    {
      title: PageTitle.contentPage,
      nodes: allPages.allSanityContentPage.nodes.map(u => ({
        ...u,
        slug: {
          ...u.slug,
          current: `${SlugPrefix.contentPage}/${u.slug.current}`,
        },
      })),
    },
  ]
}
