import { StyleBackgroundColor } from '@vp/swan'
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
} from 'react'
import { SanityCodePreview } from '../../../../types/gatsby-graphql'

type CodeContextType = {
  previewWithoutScroll?: boolean
  codeKey: string
  compactMode: boolean
  setCompactMode: Dispatch<SetStateAction<boolean>>
  darkMode: boolean
  setDarkMode: Dispatch<SetStateAction<boolean>>
  bgc: StyleBackgroundColor
  setBgc: Dispatch<SetStateAction<StyleBackgroundColor>>
  editorMode: SanityCodePreview['editorMode']
  renderMultipleComponents?: boolean
  alwaysShowEditor?: boolean
}

export type CodeContextProps = PropsWithChildren<CodeContextType>

const CodeContext = createContext<CodeContextType | null>(null)

export const CodeContextProvider = ({
  children,
  ...props
}: CodeContextProps) => {
  return <CodeContext.Provider value={props}>{children}</CodeContext.Provider>
}

export function useCodeContext() {
  const ctx = useContext(CodeContext)
  if (!ctx) {
    throw new Error(
      'useCodeContext should be accessed inside the context of CodeContext',
    )
  }
  return ctx
}
