import algoliasearch from 'algoliasearch/lite'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import {
  GATSBY_ALGOLIA_APP_ID,
  GATSBY_ALGOLIA_INDEX_NAME,
  GATSBY_ALGOLIA_SEARCH_KEY,
} from '../../../../../constants/env'
import { SearchBox } from './search-box.component'
import { SearchResult } from './search-result.component'
import './search.scss'

const searchClient = algoliasearch(
  GATSBY_ALGOLIA_APP_ID,
  GATSBY_ALGOLIA_SEARCH_KEY,
)

export const Search = ({ onClick }: { onClick: () => void }) => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={GATSBY_ALGOLIA_INDEX_NAME}
    >
      <Configure clickAnalytics />
      <SearchBox />
      <SearchResult onClick={onClick} />
    </InstantSearch>
  )
}
