import React, { useEffect, useRef } from 'react'

type Props = React.Dispatch<React.SetStateAction<string>>

const getHeaderElement = (headerTag: string) => {
  const anchorWithHeadingWrapper = [
    ...document.querySelectorAll(`${headerTag} a`),
  ]
  return anchorWithHeadingWrapper.map((tag: Element) => tag.closest(headerTag))
}

export const useVisibleLink = (setActiveId: Props) => {
  const headingElementsRef = useRef<Record<string, IntersectionObserverEntry>>(
    {},
  )

  useEffect(() => {
    const getVisibleHeading = () => {
      const visibleHeadings: IntersectionObserverEntry[] = []
      Object.keys(headingElementsRef.current).forEach(key => {
        const headingElement = headingElementsRef.current[key]
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
      })
      return visibleHeadings
    }

    const setActiveHeading = (visibleHeadings: IntersectionObserverEntry[]) => {
      let heading = visibleHeadings[0]
      // in case of multiple visible heading, we highlight the heading that is close to the top viewport
      if (visibleHeadings.length > 1) {
        const [sortedVisibleHeadings] = visibleHeadings.sort((a, b) => {
          return (
            (a.target as HTMLElement).offsetTop -
            (b.target as HTMLElement).offsetTop
          )
        })
        heading = sortedVisibleHeadings
      }
      setActiveId(heading.target.id)
    }

    const callback = (headings: IntersectionObserverEntry[]) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement
        return map
      }, headingElementsRef.current)
      const visibleHeadings = getVisibleHeading()
      if (visibleHeadings.length) {
        setActiveHeading(visibleHeadings)
      }
    }

    const observeHeadingEle = (observer: IntersectionObserver) => {
      const headingElements = [
        ...getHeaderElement('h2'),
        ...getHeaderElement('h3'),
      ]
      headingElements.forEach(element => observer.observe(element))
    }

    const observer = new IntersectionObserver(callback, {
      threshold: 1,
    })
    observeHeadingEle(observer)

    return () => observer.disconnect()
  }, [setActiveId])
}
