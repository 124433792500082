import { ErrorBoundary, Row } from '@vp/swan'
import { Maybe, SanityRow } from '../../../../types/gatsby-graphql'
import { SchemaGridItem } from '../schema-grid-item/schema-grid-item'

export const SchemaRow = ({ node: config }: { node?: Maybe<SanityRow> }) => {
  if (!config) return null
  return (
    <ErrorBoundary>
      <Row>
        {config.items?.map(item =>
          item && item._key ? (
            <SchemaGridItem
              key={item._key}
              config={item}
              verticalSpacing={config?.verticalSpacing}
            />
          ) : null,
        )}
      </Row>
    </ErrorBoundary>
  )
}
