import {
  Box,
  ErrorBoundary,
  FlexBox,
  Hidden,
  Icon,
  Link,
  Span,
  Typography,
} from '@vp/swan'
import { GatsbyImage } from 'gatsby-plugin-image'
import { WrappedInsightsClient } from 'react-instantsearch-core'
import {
  Configure,
  Highlight,
  Hits,
  Index,
  Snippet,
  connectHitInsights,
  connectStateResults,
} from 'react-instantsearch-dom'
import algoliaAnalytics from 'search-insights'
import { Heading } from '../../../..'
import { GATSBY_ALGOLIA_INDEX_NAME } from '../../../../../constants/env'
import { PageTitle } from '../../../../../constants/slug-prefix'
import { searchItem, searchMeta } from './search-result.module.scss'

const HitCount = connectStateResults(({ searchResults }) => {
  if (searchResults?.nbHits !== 0) {
    return null
  }
  return (
    <Typography my={'5'} textAlign="center" textColor="subtle">
      No results for the query.
    </Typography>
  )
})

const CustomHit = ({
  hit,
  insights,
  onClick,
}: {
  hit: any
  insights: WrappedInsightsClient
  onClick: () => void
}) => {
  const onHitClick = () => {
    insights('clickedObjectIDsAfterSearch', {
      eventName: 'Search hit clicked',
    })
    onClick()
  }
  const pageType = PageTitle[hit._type]
  return (
    <ErrorBoundary>
      <Link
        to={hit.url}
        skin="unstyled"
        withIcon
        px={{ xs: '5', sm: '6', md: '7' }}
        py={'4'}
        my={{ xs: '3', sm: 0 }}
        className={searchItem}
        onClick={onHitClick}
      >
        <Hidden xs>
          <GatsbyImage
            image={hit.imageBlock.image.asset.gatsbyImageData}
            alt=""
          />
        </Hidden>
        <Box ml={{ xs: 0, sm: '7' }} className={searchMeta}>
          <Heading element="h4">
            <Highlight attribute="title" hit={hit} tagName="mark" />
          </Heading>
          <Typography fontSize="small">
            <Snippet attribute="description" hit={hit} tagName="mark" />
          </Typography>
          <FlexBox
            alignItems="center"
            fontSize="xsmall"
            textColor="subtle"
            mt={'4'}
          >
            <Span>SWAN</Span>
            <Icon mx={'4'} iconType="chevronRight" />
            {!!pageType && (
              <>
                <Span>{pageType}</Span>
                <Icon mx={'4'} iconType="chevronRight" />
              </>
            )}
            <Span>{hit.title}</Span>
          </FlexBox>
        </Box>
      </Link>
    </ErrorBoundary>
  )
}

// Type casting to any because it isn't accepting any custom prop viz. onClick
const CustomHitWithInsights = connectHitInsights(algoliaAnalytics)(
  CustomHit,
) as any

export const SearchResult = ({ onClick }: { onClick: () => void }) => (
  <ErrorBoundary>
    <Index indexName={GATSBY_ALGOLIA_INDEX_NAME}>
      <HitCount />
      <Configure
        attributesToSnippet={['description:12']}
        snippetEllipsisText="..."
        enablePersonalization
      />
      <Hits
        hitComponent={props => (
          <CustomHitWithInsights {...props} onClick={onClick} />
        )}
      />
    </Index>
  </ErrorBoundary>
)
