import classNames from 'classnames'
import { useContext } from 'react'
import { LiveContext, LivePreview } from 'react-live'
import { CodeEditor } from './code-editor'
import { useCodeContext } from './code.context'
import { limitedPreview } from './code.module.scss'

export const CodeLivePreviewReact = () => {
  const { previewWithoutScroll } = useCodeContext()
  const { code } = useContext(LiveContext)

  if (!code) return null

  return (
    <LivePreview
      className={classNames({
        'position-relative': true,
        'swan-p-5': true,
        [limitedPreview]: !previewWithoutScroll,
      })}
    />
  )
}

export const CodeLiveEditorReact = () => {
  // @ts-ignore
  const { code, language = 'jsx', disabled, onChange } = useContext(LiveContext)
  return (
    <CodeEditor
      code={code}
      language={language}
      disabled={disabled}
      onChange={onChange}
      shareWithPreview
    />
  )
}
