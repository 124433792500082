import { Callout, ErrorBoundary, FlexBox } from '@vp/swan'
import { Maybe, SanityStatus } from '../../../types/gatsby-graphql'

export const SchemaStatuses = ({
  statuses,
}: {
  statuses?: Maybe<Array<Maybe<SanityStatus>>>
}) => {
  if (!statuses) return null
  return (
    <ErrorBoundary>
      <FlexBox
        flexWrap="wrap"
        mt={{ xs: '3', sm: '5' }}
        mb={{ xs: '5', sm: '7' }}
        fontSize="small"
        gap={3}
      >
        {statuses.map(status =>
          status && status.value ? (
            <Callout variant="inverse" key={status.title}>
              {status.title}: {status.value}
            </Callout>
          ) : null,
        )}
      </FlexBox>
    </ErrorBoundary>
  )
}
