// extracted by mini-css-extract-plugin
export var cardContainer = "schema-swan-card-module--cardContainer--bf53b";
export var cardImageBottom = "schema-swan-card-module--cardImageBottom--41d2d";
export var cardImageCenter = "schema-swan-card-module--cardImageCenter--0d18f";
export var cardImageLeft = "schema-swan-card-module--cardImageLeft--004f0";
export var cardImageRight = "schema-swan-card-module--cardImageRight--e1b49";
export var cardImageTop = "schema-swan-card-module--cardImageTop--87ed1";
export var cardTextAlignCenter = "schema-swan-card-module--cardTextAlignCenter--fa4f8";
export var cardTextAlignJustify = "schema-swan-card-module--cardTextAlignJustify--17cdc";
export var cardTextAlignRight = "schema-swan-card-module--cardTextAlignRight--9f892";
export var description = "schema-swan-card-module--description--36dc2";
export var fullBleedImage = "schema-swan-card-module--fullBleedImage--e0742";
export var image = "schema-swan-card-module--image--fa4dd";
export var imageWrap = "schema-swan-card-module--imageWrap--7ba39";
export var linkTag = "schema-swan-card-module--linkTag--d398b";
export var spacer = "schema-swan-card-module--spacer--f3d2c";
export var title = "schema-swan-card-module--title--4a7ff";