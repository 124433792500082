import { FC } from 'react'
import { BiBookContent } from 'react-icons/bi'
import { GrConnect } from 'react-icons/gr'
import { MdOutlineFoundation } from 'react-icons/md'
import { VscVersions } from 'react-icons/vsc'
import {
  // BrandIcon,
  ComponentsIcon,
  IconProps,
  SwanIcon,
  TemplatesIcon,
} from '../utils/icons.utils'

export const SwanDesignSystem = 'SWAN Design System'

export const SlugPrefix = {
  page: '',
  root: '/',
  availabilityPage: '/swan/availability',
  // brandPage: '/brand',
  swan: '/swan',
  componentPage: '/swan/components',
  foundationPage: '/swan/foundations',
  contentPage: '/swan/content',
  // templatePage: '/swan/templates',
  documentationPage: '/swan/get-started',
  versionsPage: '/swan/versions',
}

export const PageTitle: Record<keyof typeof SlugPrefix, string> = {
  page: '',
  root: 'vista.design',
  availabilityPage: 'SWAN Proxy Availability',
  // brandPage: 'Brand Center',
  swan: 'SWAN',
  componentPage: 'Components',
  foundationPage: 'Foundations',
  contentPage: 'Content',
  // templatePage: 'Templates',
  documentationPage: 'Get started',
  versionsPage: 'SWAN versions',
}

export const PageTypeIcon: Record<
  keyof typeof SlugPrefix,
  FC<IconProps> | null
> = {
  page: null,
  root: null,
  availabilityPage: GrConnect,
  // brandPage: BrandIcon,
  swan: SwanIcon,
  componentPage: ComponentsIcon,
  foundationPage: MdOutlineFoundation,
  contentPage: BiBookContent,
  // templatePage: GrDocument,
  documentationPage: TemplatesIcon,
  versionsPage: VscVersions,
}

export enum LinkType {
  External = 'External',
  Relative = 'Relative',
  DownloadExternal = 'DownloadExternal',
  DownloadFile = 'DownloadFile',
}
