import { Box, ErrorBoundary, Li, Link, Ul } from '@vp/swan'
import { Maybe, SanityLink } from '../../../types/gatsby-graphql'
import { LinkType, SlugPrefix } from '../../constants/slug-prefix'
import { Heading } from '../heading/heading.component'
import { SchemaContentBreak } from '../schema-content/schema-content-break/schema-content-break'
import { container, link } from './schema-link-cards.module.scss'

export const SchemaLinkCards = ({
  relatedDocuments,
}: {
  relatedDocuments?: Maybe<Array<Maybe<SanityLink>>>
}) => {
  if (
    !relatedDocuments ||
    !Array.isArray(relatedDocuments) ||
    !relatedDocuments.length
  ) {
    return null
  }

  // TODO: This should use generic SchemaLink
  return (
    <ErrorBoundary>
      <Box as="section" my={{ xs: '5', sm: '7' }}>
        <SchemaContentBreak node={{ style: 'hr' }} />
        <Heading element="h2" mb={'6'}>
          Related
        </Heading>
        <Ul skin="minimal" className={container}>
          {relatedDocuments.map(doc => {
            if (doc && doc._key) {
              const linkCategory = doc[doc.linkType]
              if (linkCategory) {
                return (
                  <Li key={doc._key} mr={'5'}>
                    <Link
                      className={link}
                      to={`${SlugPrefix[doc.linkType]}/${
                        linkCategory.slug.current
                      }`}
                    >
                      {doc[doc.linkType]?.title}
                    </Link>
                  </Li>
                )
              }
              let pageUrl = ''
              let pageTitle = ''
              let download = null
              if (doc.linkType === LinkType.DownloadExternal) {
                pageUrl = doc.url
                download = doc.downloadAs
                pageTitle = doc.downloadAs
              } else if (doc.linkType === LinkType.DownloadFile) {
                pageUrl = doc.file.asset.url
                download = doc.downloadAs
                pageTitle = doc.downloadAs
              } else if (doc.linkType === LinkType.External) {
                pageUrl = doc.url
                pageTitle = doc.url
              }
              return (
                <Li key={doc._key} mr={'5'}>
                  <a
                    href={pageUrl}
                    target="_blank"
                    download={download}
                    rel="noreferrer"
                    className={link}
                  >
                    {pageTitle}
                  </a>
                </Li>
              )
            }
            return null
          })}
        </Ul>
      </Box>
    </ErrorBoundary>
  )
}
