import {
  Button,
  Icon,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogNav,
} from '@vp/swan'
import { useEffect, useState } from 'react'
import menuIcon from '../../../../images/icons/menu.svg'
import { Menu } from '../../../menu/menu.component'

export const BtnMenu = ({ path }: { path: string }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    handleMenuClose()
  }, [path])

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }

  return (
    <>
      <Button
        skin="unstyled"
        display={{ xs: 'block', md: 'none' }}
        mr={{ xs: '4', sm: '6' }}
        onClick={() => setIsMenuOpen(true)}
      >
        <Icon src={menuIcon} alt="menu" size="32p" />
      </Button>
      <ModalDialog
        aria-labelledby="menu"
        variant="panel-left"
        bodyWidth="capped"
        isOpen={isMenuOpen}
        onRequestDismiss={handleMenuClose}
      >
        <ModalDialogContent>
          <ModalDialogNav>
            <ModalDialogCloseButton visuallyHiddenLabel="Close" />
          </ModalDialogNav>
          <ModalDialogBody>
            <Menu activeLink={path} />
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
