import {
  ErrorBoundary,
  TabContent,
  TabHeader,
  Tabs,
  TabsContents,
  TabsHeaders,
} from '@vp/swan'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { SchemaContent } from '..'
import { Maybe } from '../../../types/gatsby-graphql'
import {
  tabHeadingContainer,
  tabHeadingItem,
} from './schema-tabs.component.module.scss'
import { FormattedSanityTab } from './utils'

export type ExtraTab = {
  title: string
  tabId: string
  content: ReactNode
}

export const SchemaTabs = ({
  tabs,
  preTabs = [],
  postTabs = [],
  activeHeading,
  activeTab,
  setActiveTab,
}: {
  activeHeading?: string
  activeTab?: string
  setActiveTab: (tabId?: string) => void
  preTabs?: ExtraTab[]
  postTabs?: ExtraTab[]
  tabs?: Maybe<Array<FormattedSanityTab>>
}) => {
  const tabHeaderRef = useRef<HTMLDivElement | null>(null)
  const [defaultSelectedTab] = useState(() => {
    return (
      (preTabs?.length && preTabs[0]?.tabId) ||
      tabs[0]?.tabId ||
      tabs[0]?._key ||
      'tab-idx-0'
    )
  })

  // handle the sticky top
  useEffect(() => {
    if (tabHeaderRef.current) {
      const { height: headerHeight } =
        document.querySelector('header')?.getBoundingClientRect() || {}

      tabHeaderRef.current.style.top = `${headerHeight}px`
    }
  }, [])

  if (
    (!tabs || !Array.isArray(tabs) || !tabs.length) &&
    !preTabs.length &&
    !postTabs.length
  ) {
    return null
  }

  return (
    <ErrorBoundary>
      <Tabs
        stickyHeaders
        selectedTabId={activeTab || defaultSelectedTab}
        onRequestTabChange={nextTab => setActiveTab(nextTab)}
        my={{ xs: '5', sm: '7' }}
      >
        <TabsHeaders ref={tabHeaderRef} className={tabHeadingContainer}>
          {preTabs?.map(({ title, tabId }) => (
            <TabHeader key={tabId} tabId={tabId}>
              {title}
            </TabHeader>
          ))}
          {tabs?.map((tab, idx) =>
            tab ? (
              <TabHeader
                className={tabHeadingItem}
                key={tab?._key || idx}
                tabId={tab.tabId || tab._key || 'tab-idx-' + idx}
              >
                {tab.title}
              </TabHeader>
            ) : null,
          )}
          {postTabs?.map(({ title, tabId }) => (
            <TabHeader key={tabId} tabId={tabId}>
              {title}
            </TabHeader>
          ))}
        </TabsHeaders>
        <TabsContents>
          {preTabs?.map(({ content, tabId }) => (
            <TabContent key={tabId} tabId={tabId}>
              {content}
            </TabContent>
          ))}
          {tabs?.map((tab, idx) => {
            if (!tab) return null
            const tabId = tab?.tabId || tab._key || 'tab-idx-' + idx
            return (
              <TabContent key={tab._key || idx} tabId={tabId}>
                <SchemaContent
                  showToc={tab?.showToc}
                  contentBlocks={tab._rawContent}
                  allContentLinks={tab.allContentLinks}
                  activeHeading={
                    activeTab === tabId ? activeHeading : undefined
                  }
                  currentTab={tabId}
                />
              </TabContent>
            )
          })}
        </TabsContents>
      </Tabs>
    </ErrorBoundary>
  )
}
