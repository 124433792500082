import { graphql, useStaticQuery } from 'gatsby'
import { Maybe } from '../../../types/gatsby-graphql'
type SeoProps = {
  title: string
  description?: Maybe<string>
  tags?: string[]
  img?: string
  useVistaFavicon?: boolean
}
type SiteMetaData = {
  title: string
  description: string
  keywords: string[]
  siteUrl: string
}

export const SEO = ({
  title,
  description,
  tags,
  img,
  useVistaFavicon,
}: SeoProps) => {
  const { site } = useStaticQuery<{
    site: { siteMetadata: SiteMetaData }
  }>(graphql`
    query seoSiteMeta {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
        }
      }
    }
  `)
  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = (tags || site.siteMetadata.keywords).join(', ')

  return (
    <>
      <title>{metaTitle}</title>
      <meta name="og:title" content={metaTitle} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta name="og:description" content={metaDescription} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:card" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="og:type" content="website" />
      <meta property="og:image" content={img} />
      <meta property="twitter:image" content={img} />
      {useVistaFavicon !== true && (
        <link rel="icon" type="image/x-icon" href="/icons/swan-favicon.ico" />
      )}
    </>
  )
}
