import { Link as A } from '@vp/swan'
import { Link } from 'gatsby'
import { PropsWithChildren } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import slugify from 'slugify'
import { SanityLink } from '../../../../types/gatsby-graphql'
import { LinkType, SlugPrefix } from '../../../constants/slug-prefix'
import { useLinkDetail } from '../schema-content-block'
import { externalLink } from './schema-link-for-content.module.scss'

type LinkSkin = 'standard' | 'cta' | 'cta-left' | 'unstyled'

export const navHeadingQueryParam = 'heading'
export const navTabQueryParam = 'tab'

export const getActiveHeading = (location: Location) =>
  location.hash?.substring(1) || undefined

export const SchemaLinkForContent = (
  props: PropsWithChildren<{
    markKey: string
    mark: SanityLink
    useFragment?: boolean
    enforceLink?: boolean
    noIconAddons?: boolean
    className?: string
  }>,
) => {
  const details = useLinkDetail(props)
  const {
    mark: { linkType, url, to, downloadAs, linkSkin, pageTab, pageHeading },
    className,
    children,
  } = props
  if (linkType === LinkType.Relative) {
    return (
      <A as={Link} to={to} skin={linkSkin as LinkSkin} className={className}>
        {children}
      </A>
    )
  }
  if (linkType === LinkType.External) {
    return (
      <A
        as="a"
        href={url}
        rel="noreferrer"
        target="_blank"
        skin={linkSkin as LinkSkin}
        className={className}
      >
        {children}
        {props.noIconAddons ||
        linkSkin === 'cta' ||
        linkSkin === 'cta-left' ? null : (
          <BiLinkExternal className={externalLink} />
        )}
      </A>
    )
  }
  if (linkType === LinkType.DownloadExternal) {
    return (
      <A
        as="a"
        skin={linkSkin as LinkSkin}
        href={url}
        rel="noreferrer"
        download={downloadAs}
        target="_blank"
        title={`Download As ${downloadAs}`}
        className={className}
      >
        {children}
      </A>
    )
  }
  if (details?.url && linkType === LinkType.DownloadFile) {
    return (
      <A
        as="a"
        skin={linkSkin as LinkSkin}
        href={details.url}
        rel="noreferrer"
        download={downloadAs}
        target="_blank"
        title={`Download File: ${downloadAs}`}
        className={className}
      >
        {children}
      </A>
    )
  }
  if (
    details?.url &&
    (linkType === 'genericPage' || SlugPrefix[linkType] !== undefined)
  ) {
    const pageUrl =
      details.url +
      (pageTab ? `?${navTabQueryParam}=${encodeURIComponent(pageTab)}` : '') +
      (pageHeading ? `#${slugify(pageHeading)}` : '')
    return (
      <A
        as={Link}
        to={pageUrl}
        skin={linkSkin as LinkSkin}
        title={details.title || undefined}
        className={className}
      >
        {children}
      </A>
    )
  }
  if (props.enforceLink) {
    return null
  }
  if (props.useFragment) {
    return <>{children}</>
  }
  return <span>{children}</span>
}
