import {
  Box,
  Card,
  Column,
  ErrorBoundary,
  FlexBox,
  GridContainer,
  Icon,
  Row,
  Typography,
} from '@vp/swan'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { Maybe, SanityImageBlock } from '../../../types/gatsby-graphql'
import { SchemaImageBlock } from '../schema-image-block/schema-image-block'
import {
  chevronForTitle,
  imgBlock,
  imgNext,
  imgPrev,
  linkContainer,
  navMeta,
  titleWithChevron,
} from './schema-prev-next.module.scss'

const NavLink = ({
  url,
  title,
  type,
  imageBlock,
}: {
  url: string
  title: string
  type: 'prev' | 'next'
  imageBlock: Maybe<SanityImageBlock>
}) => {
  return (
    <Card
      render={props => <Link {...props} to={url} />}
      bordered
      evenHeight
      className={linkContainer}
    >
      <FlexBox justifyContent="space-between">
        <Box className={navMeta}>
          <Typography textAlign={type === 'prev' ? 'left' : 'right'}>
            {type === 'prev' ? 'Previous' : 'Next'}
          </Typography>
          <FlexBox alignItems="center" justifyContent="space-between" mt={'3'}>
            {type === 'prev' && <Icon iconType="chevronLeft" mr={'4'} />}
            {type === 'next' && (
              <Icon
                iconType="chevronRight"
                ml={'4'}
                className={chevronForTitle}
              />
            )}
            <Typography
              as="span"
              fontSize="small"
              mx={'3'}
              my={0}
              className={titleWithChevron}
            >
              {title}
            </Typography>
          </FlexBox>
        </Box>
        <Box className={type === 'prev' ? imgPrev : imgNext}>
          <span className="flex-1" />
          <SchemaImageBlock
            showCaption={false}
            node={imageBlock}
            className={classNames('swan-hidden-xs', imgBlock)}
          />
        </Box>
      </FlexBox>
    </Card>
  )
}
// TODO: Refactor to use CSS Grid, using area. Reduce DOM

export const SchemaPrevNext = ({
  prevPage,
  nextPage,
  slugPrefix,
}: {
  slugPrefix: string
  nextPage?: any
  prevPage?: any
}) => {
  if (!nextPage && !prevPage) return null
  return (
    <ErrorBoundary>
      <GridContainer mt={8}>
        <Row>
          <Column span={5} spanLg={4} spanXl={3} spanXs={6}>
            {!!prevPage && (
              <NavLink
                url={`${slugPrefix}/${prevPage.slug.current}`}
                title={prevPage.title}
                type="prev"
                imageBlock={prevPage.imageBlock}
              />
            )}
          </Column>
          <Column
            offset={2}
            offsetLg={4}
            offsetXl={6}
            span={5}
            spanLg={4}
            spanXl={3}
            spanXs={6}
          >
            {!!nextPage && (
              <NavLink
                url={`${slugPrefix}/${nextPage.slug.current}`}
                title={nextPage.title}
                type="next"
                imageBlock={nextPage.imageBlock}
              />
            )}
          </Column>
        </Row>
      </GridContainer>
    </ErrorBoundary>
  )
}
