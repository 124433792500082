import { useMemo } from 'react'
import { Toc } from '../../../toc/toc.component'
import { useContentContext } from '../content.context'

export const ContentToc = () => {
  const { tocMap, currentTab } = useContentContext()

  const currentTabQueryParam = currentTab ? `?tab=${currentTab}` : ''

  const tocLinks = useMemo(
    () =>
      Object.values(tocMap).flatMap(toc => [
        { slug: toc.slug, text: toc.text, isSubToc: false },
        ...toc.subToc.map(subToc => ({ ...subToc, isSubToc: true })),
      ]),
    [tocMap],
  )

  return <Toc tocLinks={tocLinks} currentTabQueryParam={currentTabQueryParam} />
}
