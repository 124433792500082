import { MutableRefObject, useEffect } from 'react'

export const usePreventSlashBubbling = (
  ref: MutableRefObject<HTMLElement>,
  name?: string,
) => {
  useEffect(() => {
    const ele = ref.current

    const preventBubblingForSlash = (event: KeyboardEvent) => {
      if (event.key === '/') {
        event.stopPropagation()
      }
    }

    if (ele) {
      ele.addEventListener('keydown', preventBubblingForSlash)

      return () => {
        ele.removeEventListener('keydown', preventBubblingForSlash)
      }
    }
  }, [ref, name])
}
