import { FlexBox, SwanCoreStyleProps } from '@vp/swan'
import { BtnCopy } from '../../buttons/btn-copy/btn-copy.component'
import { BtnBgcSelector } from './btn-bgc-selector/btn-bgc-selector.component'
import { BtnCompactMode } from './btn-compact-mode/btn-compact-mode.component'
import { BtnDarkMode } from './btn-dark-mode/btn-dark-mode.component'
import { BtnPrettify } from './btn-prettify/btn-prettify.component'
import { BtnReset } from './btn-reset/btn-reset.component'
import { BtnShare } from './btn-share/btn-share.component'

export const CodeOptions = ({
  content,
  language,
  onFormat,
  onReset,
  shareWithPreview,
  renderMultipleComponents,
  ...props
}: {
  content: string
  language: string
  onFormat: () => void
  onReset: () => void
  shareWithPreview?: boolean
  renderMultipleComponents?: boolean
} & SwanCoreStyleProps) => {
  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      gap="between-actions"
      px="5"
      pt="5"
      {...props}
    >
      <FlexBox gap="between-actions" compactMode>
        <BtnCopy content={content} />
        <BtnShare
          content={content}
          language={`${language}${shareWithPreview ? '-preview' : ''}`}
          renderMultipleComponents={renderMultipleComponents}
        />
        {shareWithPreview && <BtnPrettify onFormat={onFormat} />}
      </FlexBox>
      <FlexBox alignItems="center" gap="between-actions" compactMode>
        {shareWithPreview && (
          <>
            <BtnDarkMode />
            <BtnCompactMode />
            <BtnBgcSelector />
            <BtnReset onReset={onReset} />
          </>
        )}
      </FlexBox>
    </FlexBox>
  )
}
