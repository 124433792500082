import { Box } from '@vp/swan'
import { PageProps } from 'gatsby'
import { useRef } from 'react'
import { TopNav } from '../layout/top-nav/top-nav.component'
import { Menu } from '../menu/menu.component'
import { menu } from './header-and-sidenav.module.scss'

export const HeaderAndSidenav = ({ ...props }: PageProps) => {
  const menuWrap = useRef<HTMLDivElement | null>(null)
  const headerWrap = useRef<HTMLDivElement | null>(null)

  const {
    location: { pathname },
  } = props

  const isSwanPage = pathname !== '/'

  return (
    <>
      <TopNav ref={headerWrap} isSwanPage={isSwanPage} path={pathname} />
      {isSwanPage && (
        <Box
          className={menu}
          display={{ xs: 'none', md: 'block' }}
          ref={menuWrap}
        >
          <Menu activeLink={pathname} />
        </Box>
      )}
    </>
  )
}
