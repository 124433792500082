import {
  Accordion,
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleSummary,
  CollapsibleSummaryButton,
  Link,
} from '@vp/swan'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { PageTitle, SlugPrefix } from '../../constants/slug-prefix'
import { useAllPages } from '../../hooks/use-all-pages.hook'
import { Logo } from '../layout/top-nav/logo/logo.component'
import {
  active,
  linkContainer,
  linkItem,
  logo,
  menu,
  menuItem,
} from './menu.module.scss'

const HIDDEN_MENU_ITEM = ['Content', 'Templates']

const landingPage = {
  [PageTitle.componentPage]: {
    id: SlugPrefix.componentPage,
    slug: { current: SlugPrefix.componentPage },
    title: 'Overview',
  },
  [PageTitle.foundationPage]: {
    id: SlugPrefix.foundationPage,
    slug: { current: SlugPrefix.foundationPage },
    title: 'Overview',
  },
  [PageTitle.documentationPage]: {
    id: SlugPrefix.documentationPage,
    slug: { current: SlugPrefix.documentationPage },
    title: 'Overview',
  },
} as const

const getExtended = (activeLink?: string) =>
  Object.keys(landingPage).reduce(
    (obj, path) => ({
      ...obj,
      [path]: activeLink
        ? activeLink.includes(landingPage[path].slug.current)
        : false,
    }),
    {},
  )

const getPageTitle = (activeLink?: string) => {
  let titleKey = ''
  Object.keys(SlugPrefix).forEach(key => {
    const slug = SlugPrefix[key]
    if (activeLink.includes(slug)) {
      titleKey = key
    }
  })
  return PageTitle[titleKey]
}

export const Menu = ({ activeLink }: { activeLink?: string }) => {
  const [expandedCollapsibles, setExpandedCollapsibles] = useState(getExtended)

  useEffect(() => {
    if (activeLink === `${SlugPrefix.swan}/`) {
      setExpandedCollapsibles(getExtended())
    }
  }, [activeLink])

  useEffect(() => {
    const titleKey = getPageTitle(activeLink)
    if (
      Object.keys(expandedCollapsibles).includes(titleKey) &&
      !expandedCollapsibles[titleKey]
    ) {
      handleExpandedCollapsibles(titleKey, true)
    }
    // if we include expandedCollapsibles in the dependency then it will prevent the closing of the CollapsibleSummary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLink])

  const allPages = useAllPages()
  const filteredAllPages = allPages.reduce((arr, page) => {
    if (page.nodes.length && !HIDDEN_MENU_ITEM.includes(page.title)) {
      return arr.concat({
        ...page,
        path: landingPage[page.title].slug,
        nodes: [landingPage[page.title], ...page.nodes],
      })
    }
    return arr
  }, [])

  const handleExpandedCollapsibles = (
    collapsibleId: string,
    expanded: boolean,
  ) => {
    setExpandedCollapsibles(prevValue => ({
      ...prevValue,
      [collapsibleId]: expanded,
    }))
  }

  return (
    <nav className={menu}>
      <Box className={logo}>
        <Logo isSwanPage={true} />
      </Box>
      <Accordion
        className={menuItem}
        expandedCollapsibles={expandedCollapsibles}
        onRequestExpandedChange={handleExpandedCollapsibles}
      >
        {filteredAllPages.map(entity => (
          <Collapsible key={entity.title} collapsibleId={entity.title}>
            <CollapsibleSummary>
              <CollapsibleSummaryButton>
                {entity.title}
              </CollapsibleSummaryButton>
            </CollapsibleSummary>
            <CollapsibleContent className={linkContainer}>
              {entity.nodes.map(u => (
                <Link
                  key={u.id}
                  skin="unstyled"
                  my={'4'}
                  to={u.slug.current}
                  className={classNames(linkItem, {
                    [active]: `${u.slug.current}/` === activeLink,
                  })}
                >
                  {u.title}
                </Link>
              ))}
            </CollapsibleContent>
          </Collapsible>
        ))}
      </Accordion>
    </nav>
  )
}
