import BlockContent from '@sanity/block-content-to-react'
import { Responsive, StyleSpaceWithAuto } from '@vp/swan'
import { PropsWithChildren } from 'react'
import { SanityBlock } from '../../../../../types/gatsby-graphql'
import { Heading, HeadingElement } from '../../../heading/heading.component'
import { useContentContext, useHeadingId } from '../content.context'

const topSpacingH3: Responsive<StyleSpaceWithAuto> = { xs: 6, sm: 8 }

const headingToMtMapping: Record<
  HeadingElement,
  { mt: Responsive<StyleSpaceWithAuto>; pt?: Responsive<StyleSpaceWithAuto> }
> = {
  h1: {
    mt: {
      xs: 4,
      sm: 5,
      md: 6,
      lg: 7,
    },
  },
  h2: { mt: { xs: 7, sm: 9 } },
  h3: { mt: topSpacingH3 },
  h4: { mt: { xs: 4, sm: 5 } },
  h5: { mt: 3 },
  h6: { mt: 3 },
}

export const BlockRenderer = (
  props: PropsWithChildren<{
    node: SanityBlock
    tocMap: Record<string, string>
  }>,
) => {
  const { style = 'normal' } = props.node
  const headingId = useHeadingId(props?.node?._key)
  const { currentTab } = useContentContext()
  const currentTabQueryParam = currentTab ? `?tab=${currentTab}` : ''
  const sectionLink = `${currentTabQueryParam}#${headingId}`

  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(style)) {
    return (
      <Heading
        element={style}
        headingId={headingId}
        headingLink={sectionLink}
        {...headingToMtMapping[style]}
      >
        {props.children}
      </Heading>
    )
  }

  if (style === 'heading') {
    return (
      <Heading element="h3" headingId={headingId} mt={topSpacingH3}>
        {props.children}
      </Heading>
    )
  }

  return BlockContent.defaultSerializers.types.block(props)
}
