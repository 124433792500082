import { Accordion, BasicCollapsible, ErrorBoundary } from '@vp/swan'
import {
  Maybe,
  SanityAccordion,
  SanityAccordionItem,
} from '../../../../types/gatsby-graphql'
import { SchemaMarkdown } from '../../schema-markdown/schema-markdown'

export const SchemaAccordion = ({
  node,
}: {
  node?: Maybe<SanityAccordion>
}) => {
  return (
    <ErrorBoundary>
      <Accordion single={node.single} skin="standard" fullBleed>
        {node.accordionItems.map((item: SanityAccordionItem) => (
          <BasicCollapsible
            key={item._key}
            collapsibleId={item._key}
            heading={item.title}
          >
            <SchemaMarkdown node={item.markdown} />
          </BasicCollapsible>
        ))}
      </Accordion>
    </ErrorBoundary>
  )
}
