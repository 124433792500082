import { Callout, CalloutProps, ErrorBoundary } from '@vp/swan'
import { Maybe, SanityCallout } from '../../../../types/gatsby-graphql'
import { SpacingType } from '../../../../types/spacing'

export const SchemaCallout = ({
  node: config,
}: {
  node?: Maybe<SanityCallout>
}) => {
  if (!config) return null
  return (
    <ErrorBoundary>
      <Callout
        skin={config.skin as CalloutProps['skin']}
        variant={config.variant as CalloutProps['variant']}
        {...(config.spacing as SpacingType)}
      >
        {config.title}
      </Callout>
    </ErrorBoundary>
  )
}
