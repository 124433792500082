// extracted by mini-css-extract-plugin
export var codeEditor = "code-module--codeEditor--85e38";
export var codeEditorCode = "code-module--codeEditorCode--a9e93";
export var codeEditorContainer = "code-module--codeEditorContainer--c5197";
export var codeEditorToggleContainer = "code-module--codeEditorToggleContainer--68da1";
export var codeEditorWrapper = "code-module--codeEditorWrapper--afbf4";
export var codeError = "code-module--codeError--47b78";
export var limitedPreview = "code-module--limitedPreview--18473";
export var playgroundContainer = "code-module--playgroundContainer--3623c";
export var previewContainer = "code-module--previewContainer--ce616";
export var previewContainerBackground = "code-module--previewContainerBackground--166eb";
export var previewContainerBackgroundDark = "code-module--previewContainerBackgroundDark--6a5ff";