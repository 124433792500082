import {
  SWAN_JS_KEY_MAP,
  ScreenClassProvider,
  SwanProvider,
  getSwanJsUrl,
  useBrowserClasses,
} from '@vp/swan'
import { Link, Script, ScriptStrategy } from 'gatsby'
import { ReactNode } from 'react'

const scriptKeys = [
  SWAN_JS_KEY_MAP.accordion,
  SWAN_JS_KEY_MAP.alertBox,
  SWAN_JS_KEY_MAP.core,
  SWAN_JS_KEY_MAP.modalDialog,
  SWAN_JS_KEY_MAP.popover,
  SWAN_JS_KEY_MAP.progress,
  SWAN_JS_KEY_MAP.progressiveImage,
  SWAN_JS_KEY_MAP.tabs,
  SWAN_JS_KEY_MAP.toggleSwitch,
]

export const GOOGLE_ANALYTICS_ID = 'GTM-5HGSQD2L'

export const SwanConfiguration = ({ children }: { children: ReactNode }) => {
  useBrowserClasses(true)
  return (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_ANALYTICS_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="GTM"
        ></iframe>
      </noscript>
      <SwanProvider globalLinkComponent={Link}>
        <ScreenClassProvider>{children}</ScreenClassProvider>
      </SwanProvider>
      {/* Loading scripts needed for executing Vanilla implementation */}
      {scriptKeys.map(key => {
        const url = getSwanJsUrl(key)
        if (!url) return null
        /**
         * Setting the strategy to "idle" as these scripts are code block specific and even in that to Vanilla tab.
         * Most of our users are React based. By the time they visit any, it will be settled muh before that.
         */
        return <Script key={key} src={url} strategy={ScriptStrategy.idle} />
      })}
    </>
  )
}
