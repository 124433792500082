import { Link } from 'gatsby'
import { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react'

export type LinkItem = {
  url: string
  isExternal?: boolean
  target?: HTMLAttributeAnchorTarget
  title?: string
  replace?: boolean
}

type WindowLocation<S = unknown> = Window['location']
interface LinkGetProps {
  isCurrent: boolean
  isPartiallyCurrent: boolean
  href: string
  location: WindowLocation
}

export const NavLink = ({
  link,
  className,
  activeClassName,
  partiallyActive,
  getProps,
  children,
}: PropsWithChildren<{
  link: LinkItem
  className?: string
  activeClassName?: string
  partiallyActive?: boolean
  getProps?: (props: LinkGetProps) => {}
}>) => {
  if (link.isExternal) {
    // TODO: Think for applying ...otherProps on <a>
    return (
      <a href={link.url} target={link.target} className={className}>
        {children || link.title}
      </a>
    )
  }
  return (
    <Link
      to={link.url}
      className={className}
      partiallyActive={partiallyActive}
      activeClassName={activeClassName}
      getProps={getProps}
      replace={link.replace ?? false}
    >
      {children || link.title}
    </Link>
  )
}
