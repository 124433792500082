import { Button, Icon, SwanStyleProps } from '@vp/swan'
import { useEffect, useRef } from 'react'
import { scrollBtn } from './back-to-top.module.scss'

export const BtnBackToTop = (props: SwanStyleProps) => {
  const btnRef = useRef<HTMLAnchorElement>()

  useEffect(() => {
    const listener = () => {
      if (btnRef.current) {
        if (window.scrollY > window.innerHeight / 2) {
          btnRef.current.style.right = '30px'
        } else {
          btnRef.current.style.right = '-120px'
        }
      }
    }
    window.addEventListener('scroll', listener, { passive: true })
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  return (
    <Button
      render={props => (
        <a
          href="#maincontentstart"
          aria-label="Back to top"
          ref={btnRef}
          className={props.className}
        >
          {props.children}
        </a>
      )}
      buttonShape="round"
      title="Back To Top"
      {...props}
      className={scrollBtn}
    >
      <Icon iconType="caretUp" />
    </Button>
  )
}
