import { Breadcrumb, Breadcrumbs, SwanStyleProps } from '@vp/swan'
import { LinkItem, NavLink } from '../base/nav-link/nav-link.component'

type BreadcrumbProps = {
  pageLinks: LinkItem[]
} & SwanStyleProps

export const PageBreadCrumbs = ({
  pageLinks = [],
  ...otherProps
}: BreadcrumbProps) => {
  if (!pageLinks.length) return null
  return (
    <Breadcrumbs
      aria-label="breadcrumbs"
      mb={{ xs: '5', sm: '7' }}
      {...otherProps}
    >
      {pageLinks.map(u => (
        <Breadcrumb key={u.title}>
          <NavLink link={u} />
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  )
}
