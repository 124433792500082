import { Box, FluidImage, Typography } from '@vp/swan'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { PolicyLinks } from '../../../constants/urls'
import vistaLogo from '../../../images/icons/vista-logo.svg'
import { LinkItem, NavLink } from '../../base/nav-link/nav-link.component'
import {
  footerLinks,
  linkGap,
  logo,
  navGroup,
  paddingTop,
  textDecorationNone,
} from './footer.module.scss'

const aboutPages: LinkItem[] = [
  {
    title: 'Terms of use',
    url: PolicyLinks.terms,
    isExternal: true,
    target: '_blank',
  },
  {
    title: 'Privacy policy',
    url: PolicyLinks.privacy,
    isExternal: true,
    target: '_blank',
  },
  {
    title: 'Legal notice',
    url: PolicyLinks.legal,
    isExternal: true,
    target: '_blank',
  },
]

export const Footer = () => {
  return (
    <Box as="footer" className={classNames(navGroup)}>
      <nav className={classNames(linkGap, paddingTop)}>
        <Box>
          <Link to="/">
            <FluidImage className={logo} src={vistaLogo} alt="Vista" />
          </Link>
        </Box>
        <Box className={footerLinks}>
          {aboutPages.map(u => (
            <NavLink key={u.title} link={u} className={textDecorationNone}>
              <Typography fontSize="small" textColor="subtle" as="span">
                {u.title}
              </Typography>
            </NavLink>
          ))}
        </Box>
      </nav>
    </Box>
  )
}
