import {
  Box,
  Listbox,
  ListboxButton,
  ListboxList,
  ListboxOption,
  ListboxPopover,
  Typography,
} from '@vp/swan'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useVisibleLink } from '../../hooks/use-visible-link'
import { NavLink } from '../base/nav-link/nav-link.component'
import {
  activeNavLink,
  container,
  nav,
  navLink,
  navList,
  subTocItem,
} from './toc.module.scss'

export type TocLink = { text: string; slug: string; isSubToc: boolean }

const defaultTocContent: TocLink = {
  slug: '',
  text: 'Jump to...',
  isSubToc: false,
}

export const Toc = ({
  tocLinks,
  currentTabQueryParam = '',
}: {
  tocLinks: TocLink[]
  currentTabQueryParam?: string
}) => {
  const [activeId, setActiveId] = useState('')
  const ref = useRef<HTMLDivElement | null>(null)

  useVisibleLink(setActiveId)

  // handle the sticky top
  useEffect(() => {
    const { height: headerHeight } =
      document.querySelector('header')?.getBoundingClientRect() || {}
    const { height: tabsHeight } = document
      .querySelector('[role="tablist"]')
      ?.getBoundingClientRect() || { height: 0 }

    ref.current.style.top = `${headerHeight + tabsHeight}px`
    ref.current.style.maxHeight = `calc(100vh - ${headerHeight + tabsHeight}px)`
  }, [])

  const handleChange = (selectedValue: Set<string>) => {
    const [slug] = [...selectedValue]
    setActiveId(slug)
    if (slug) {
      window.location.hash = slug
    }
  }

  return (
    <Box as="aside" pt={'3'} pb={'3'} className={container} ref={ref}>
      <Typography textColor="subtle" mb={'5'}>
        On this page
      </Typography>
      <Box as="nav" display={{ xs: 'none', md: 'block' }} className={nav}>
        <ul className={navList}>
          {tocLinks.map(toc => (
            <li key={toc.slug}>
              <NavLink
                link={{
                  url: `${currentTabQueryParam}#${toc.slug}`,
                  replace: true,
                }}
                className={classNames(navLink, {
                  [activeNavLink]: activeId === toc.slug,
                  [subTocItem]: toc.isSubToc,
                })}
              >
                {toc.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </Box>
      <Listbox
        display={{ md: 'none' }}
        fullWidth
        selectedKeys={new Set('')}
        onSelectionChange={handleChange}
      >
        <ListboxButton aria-label="Jump to..." />
        <ListboxPopover>
          <ListboxList>
            {[defaultTocContent, ...tocLinks]?.map(u => (
              <ListboxOption key={u.slug} textValue={u.text}>
                <span className={classNames({ [subTocItem]: u?.isSubToc })}>
                  {u.text}
                </span>
              </ListboxOption>
            ))}
          </ListboxList>
        </ListboxPopover>
      </Listbox>
    </Box>
  )
}
