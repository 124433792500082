import { Typography } from '@vp/swan'
import { SlugPrefix } from '../../../../constants/slug-prefix'
import swanLogo from '../../../../images/icons/swan.svg'
import vistaLogo from '../../../../images/icons/vista-tm.svg'
import { NavLink } from '../../../base/nav-link/nav-link.component'
import { navLink } from './logo.module.scss'

export const Logo = ({ isSwanPage }: { isSwanPage: boolean }) => {
  return (
    <NavLink
      link={{
        url: isSwanPage ? SlugPrefix.swan : SlugPrefix.root,
      }}
      className={navLink}
    >
      <img
        src={isSwanPage ? swanLogo : vistaLogo}
        alt={isSwanPage ? 'Swan' : 'Vista'}
      />
      <Typography
        ml={'4'}
        display={{ xs: 'none', sm: 'none', md: 'block' }}
        fontWeight="bold"
      >
        {isSwanPage ? 'SWAN Design System' : 'UX + Design Team'}
      </Typography>
    </NavLink>
  )
}
