import { Divider } from '@vp/swan'
import { Maybe, SanityContentBreak } from '../../../../types/gatsby-graphql'

export const SchemaContentBreak = ({
  node,
}: {
  node?: Maybe<SanityContentBreak>
}) => {
  switch (node.style) {
    case 'hr':
      return <Divider my={'7'} />
    case 'br':
      return <br />
    default:
      return null
  }
}
