import * as React from 'react'
import { AiFillFileText } from 'react-icons/ai'

export type IconProps = {
  style?: React.CSSProperties
  className?: string
}

export const BlogsIcon = ({ style, className }: IconProps) => (
  <AiFillFileText
    style={style}
    className={className}
    width="1em"
    height="1em"
  />
)

export const BrandIcon = ({ style, className }: IconProps) => (
  <svg
    style={style}
    className={className}
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 28 29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.594 11.838C4.432 6.573 9.164 3 15.058 3c3.528 0 7.825 1.726 10.03 4.591M5.721 19.477a10.12 10.12 0 01-.511-3.19c0-5.593 4.31-9.934 9.907-9.934 5.55 0 10.582 4.607 9.765 10.145a.452.452 0 01-.027.102c-.323 1.576-1.368 2.817-3.03 2.877-4.645.168-2.356-6.287-7.098-6.287-1.412 0-2.95 1.233-2.95 3.308-.192 3.357 2.309 8.511 8.35 9.646m-4.733 1.774c-4.64-2.812-7.072-6.98-6.895-11.787.135-3.601 2.976-6.547 6.663-6.547 3.674 0 6.664 2.931 6.664 6.564m4.88-6.205c.403.631 1.575 2.73 1.185 6.45-.321 3.054-2.727 6.329-6.574 6.329-3.388 0-6.155-2.538-6.59-5.985M6.003 25.658A13.266 13.266 0 012 16.144c0-.46.023-.916.07-1.364"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const ComponentsIcon = ({ style, className }: IconProps) => (
  <svg
    style={style}
    className={className}
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M15 2l12 6.667V22l-12 6.667L3 22V8.667L15 2z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M3 8.667l12 6.666 12-6.666M15 15.333v13.334"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
)

export const SwanIcon = ({ style, className }: IconProps) => (
  <svg
    style={style}
    className={className}
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 273.19 352.88"
  >
    <defs>
      <style>{`.cls-1{fill - rule:evenodd;}`}</style>
    </defs>
    <path
      className="cls-1"
      d="M241.6,184c13.7-7.9-.5-28.3,3.4-45.8,1.11-4.93,2.13-4.95,4.58-8.62,58.36-87.62-104.43-180.39-205-91.44C19,59.07,2.73,87,.43,119.86a97.73,97.73,0,0,0,26.86,77.26C46.22,217,72.51,229.64,97.59,239.9c22.14,9,44.92,16.57,66.61,26.71,18.19,8.51,37.36,19.8,48.21,37.27,8.9,14.32,10.63,30.36,8.2,46.63C236.17,340,250.45,326.56,260,310.26c8.11-13.87,12.46-29.66,10.92-45.77-1.6-16.58-9.16-31.34-20.29-43.54-14-15.33-32.91-26-52.19-33.23-27.93-10.44-59.78-19.72-82-40.52-10.89-8.85-19.27-19.81-20.75-34.17,0,0,0,0,0,0a47.05,47.05,0,0,1,72.1-46.69c4.84,3.14,9.46,7,12.33,12l2.61,4.56c4.24,7.39,3.49,15.12,4,23.51,7.37,4.24,13.46,6.14,17.74,13.86ZM90.37,306.52a45,45,0,1,0-43.6,46.34A45.08,45.08,0,0,0,90.37,306.52Z"
    />
  </svg>
)

export const TemplatesIcon = ({ style, className }: IconProps) => (
  <svg
    style={style}
    className={className}
    width="1em"
    height="1em"
    viewBox="1 1 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
      d="M4 4h22v23H4z"
    />
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
      d="M4 4h22v8H4zM4 12h8v15H4z"
    />
  </svg>
)
