import { RefObject, useEffect } from 'react'

export function useOutsideClick(
  ref: RefObject<HTMLElement>,
  onOutsideClick: () => void,
) {
  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as unknown as any)
      ) {
        onOutsideClick()
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [onOutsideClick, ref])
}
