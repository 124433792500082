import { GatsbyBrowser } from 'gatsby'
import * as React from 'react'
import algoliaAnalytics from 'search-insights'
import { v4 as uuidV4 } from 'uuid'
import { SwanConfiguration } from './src/components/configs/swan-configuration'
import { CookieNotifier } from './src/components/cookie-notifier/cookie-notifier'
import { HeaderAndSidenav } from './src/components/header-and-sidenav/header-and-sidenav.component'
import { UserPreferencesContextProvider } from './src/components/layout'
import {
  GATSBY_ALGOLIA_APP_ID,
  GATSBY_ALGOLIA_SEARCH_KEY,
} from './src/constants/env'
import { SlugPrefix } from './src/constants/slug-prefix'

algoliaAnalytics('init', {
  appId: GATSBY_ALGOLIA_APP_ID,
  apiKey: GATSBY_ALGOLIA_SEARCH_KEY,
})

// All that's needed in user token is a unique id
algoliaAnalytics('setUserToken', uuidV4())

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return (
    <SwanConfiguration>
      <UserPreferencesContextProvider>{element}</UserPreferencesContextProvider>
    </SwanConfiguration>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return (
    <>
      <HeaderAndSidenav {...props} />
      {element}
      <CookieNotifier />
    </>
  )
}

/**
 *
 * For the landing pages we are restoring the scroll position for better UX and
 * for other pages, we will scroll the page to the top even on the browser back
 */
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname, action, hash } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    `${SlugPrefix.componentPage}/`,
    `${SlugPrefix.foundationPage}/`,
    `${SlugPrefix.contentPage}/`,
  ]

  if (!hash) {
    if (action === 'PUSH' || scrollToTopRoutes.indexOf(pathname) < 0) {
      window.setTimeout(() => window.scrollTo(0, 0), 0)
    } else {
      const savedPosition = getSavedScrollPosition(location)
      window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 0)
    }
    return false
  }
  return true
}
