import { Box, Callout, ErrorBoundary } from '@vp/swan'
import { Maybe, SanityTag } from '../../../types/gatsby-graphql'

export const SchemaTags = ({
  tags,
}: {
  tags?: Maybe<Array<Maybe<SanityTag>>>
}) => {
  if (!tags || !tags.length) return null
  return (
    <ErrorBoundary>
      <Box as="section" mb={'5'}>
        {tags.map(tag =>
          tag && tag.value ? (
            <Callout key={tag.value} variant="standard" mr={'4'}>
              {tag.value}
            </Callout>
          ) : null,
        )}
      </Box>
    </ErrorBoundary>
  )
}
