import BlockContent from '@sanity/block-content-to-react'
import { ErrorBoundary } from '@vp/swan'
import { SchemaImageBlock } from '../../schema-image-block/schema-image-block'
import { SchemaInlineImage } from '../../schema-inline-image/schema-inline-image'
import { SchemaTabSection } from '../../schema-tab-section/schema-tab-section.component'
import { SchemaAccordion } from '../schema-accordion/schema-accordion'
import { SchemaAlertBox } from '../schema-alert-box/schema-alert-box'
import { SchemaButton } from '../schema-button/schema-button'
import { SchemaCallout } from '../schema-callout/schema-callout'
import { SchemaCard } from '../schema-card/schema-card'
import { CodePreview } from '../schema-code/code.component'
import { SchemaContentBreak } from '../schema-content-break/schema-content-break'
import { SchemaGrid } from '../schema-grid/schema-grid'
import { SchemaLinkForContent } from '../schema-link-for-content/schema-link-for-content'
import { SchemaProps } from '../schema-props/schema-props'
import { SchemaSwanCard } from '../schema-swan-card/schema-swan-card'
import { SchemaTable } from '../schema-table/schema-table'
import { BlockRenderer } from './block-renderer/block-renderer'
import { ContentContainer } from './container/content-container'
import { useContentContext } from './content.context'
import { schemaContentBlock } from './schema-content-block.module.scss'

const serializers = {
  types: {
    codePreview: CodePreview,
    grid: SchemaGrid,
    inlineImage: SchemaInlineImage,
    imageBlock: SchemaImageBlock,
    propsBlock: SchemaProps,
    table: SchemaTable,
    card: SchemaCard,
    tabSection: SchemaTabSection,
    swanCard: SchemaSwanCard,
    button: SchemaButton,
    callout: SchemaCallout,
    block: BlockRenderer,
    accordion: SchemaAccordion,
    contentBreak: SchemaContentBreak,
    alertBox: SchemaAlertBox,
  },
  container: ContentContainer,
  marks: {
    link: SchemaLinkForContent,
  },
}

export const SchemaContentBlock = () => {
  const { contentBlocks: blocks } = useContentContext()
  if (!blocks || !Array.isArray(blocks) || blocks.length === 0) return null
  return (
    <div className={schemaContentBlock}>
      <ErrorBoundary>
        <BlockContent
          blocks={blocks}
          serializers={serializers}
          ignoreUnknownTypes
        />
      </ErrorBoundary>
    </div>
  )
}
