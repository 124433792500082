import {
  className,
  ErrorBoundary,
  GridContainer,
  Row,
  StyleBackgroundColor,
} from '@vp/swan'
import { useMemo } from 'react'
import { Maybe, SanityGrid } from '../../../../types/gatsby-graphql'
import { SchemaGridItem } from '../schema-grid-item/schema-grid-item'
import { skinContained } from './schema-grid.module.scss'

const getGridClasses = (config?: Maybe<SanityGrid>) => {
  return className({
    [skinContained]: config?.skin === 'contained',
  })
}

export const SchemaGrid = ({ node: config }: { node?: Maybe<SanityGrid> }) => {
  const bgc = useMemo(
    () =>
      config?.darkMode
        ? (config?.backgroundColor as StyleBackgroundColor) || 'strong'
        : undefined,
    [config?.darkMode, config?.backgroundColor],
  )
  if (!config) return null
  return (
    <ErrorBoundary>
      <GridContainer
        className={getGridClasses(config)}
        darkMode={config.darkMode}
        bgc={bgc}
      >
        <Row>
          {config.items?.map(item =>
            item && item._key ? (
              <SchemaGridItem
                key={item._key}
                config={item}
                verticalSpacing={config?.verticalSpacing}
              />
            ) : null,
          )}
        </Row>
      </GridContainer>
    </ErrorBoundary>
  )
}
