import { ErrorBoundary, Span } from '@vp/swan'
import Image from 'gatsby-plugin-sanity-image'
import { Maybe, SanityInlineImage } from '../../../types/gatsby-graphql'
import { imageAlign } from './schema-inline-image.module.scss'

export const SchemaInlineImage = ({
  node: config,
}: {
  node?: Maybe<SanityInlineImage>
}) => {
  const asset = config?._rawImage?.asset || config?.image?.asset
  if (!asset) return null
  return (
    <ErrorBoundary>
      <Span className={imageAlign}>
        <Image {...config?._rawImage} asset={asset} alt={config?.alt || ''} />
      </Span>
    </ErrorBoundary>
  )
}
