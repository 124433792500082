const monthMap = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

export const formattedDateTime = (dateStr: string, withTime = true): string => {
  try {
    const date = new Date(dateStr)
    const time = withTime
      ? `  ${date.getHours().toString().padStart(2, '0')}:${date
          .getMinutes()
          .toString()
          .padStart(2, '0')}`
      : ''
    return `${
      monthMap[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}${time}`
  } catch {
    return dateStr
  }
}
