import pako from 'pako'

export function compressContent(content: string) {
  // Convert data to Uint8Array
  const encoder = new TextEncoder()
  const dataArray = encoder.encode(content)

  // Compress data using gzip
  const compressedData = pako.gzip(dataArray, { level: 9 })

  // Convert compressed data to Base64 string for URL encoding
  const base64CompressedData = window.btoa(
    String.fromCharCode.apply(null, compressedData),
  )

  // Generate the URL with the compressed data
  return base64CompressedData
}

export function decompressContent(content: string) {
  // Decode the Base64 compressed data
  const compressedData = window.atob(content)

  // Convert compressed data to Uint8Array
  const dataArray = new Uint8Array(compressedData.length)
  for (let i = 0; i < compressedData.length; i++) {
    dataArray[i] = compressedData.charCodeAt(i)
  }

  // Decompress data using gzip
  const decompressedData = pako.ungzip(dataArray, { to: 'string' })

  return decompressedData
}
