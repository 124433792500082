import { ErrorBoundary } from '@vp/swan'
import ReactMarkdown, { Components } from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

export const SchemaMarkdown = ({
  node,
  components,
}: {
  node: string
  components?: Components
}) => {
  return (
    <ErrorBoundary>
      <ReactMarkdown
        components={components}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        className="swan-markup"
      >
        {node}
      </ReactMarkdown>
    </ErrorBoundary>
  )
}
