import { Button, Icon, SwanCoreStyleProps } from '@vp/swan'

export const BtnPrettify = ({
  onFormat,
  ...props
}: SwanCoreStyleProps & {
  onFormat: () => void
}) => (
  <Button iconPosition="left" {...props} onClick={onFormat}>
    <Icon iconType="sparkle" />
    Prettify
  </Button>
)
