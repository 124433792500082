import { Box, tokensRaw, useModeExtractor } from '@vp/swan'
import classNames from 'classnames'
import Highlight, { Language, Prism } from 'prism-react-renderer'
import vsLight from 'prism-react-renderer/themes/vsLight'
import vsDark from 'prism-react-renderer/themes/vsDark'
import { CSSProperties, Fragment, useRef, useState } from 'react'
import Editor from 'react-simple-code-editor'
import formatCode from '../../../utils/code.utils'
import { CodeOptions } from './code-options'
import { EDITOR_MODE } from './code.constants'
import { useCodeContext } from './code.context'
import { codeEditor, codeEditorCode } from './code.module.scss'
import { usePreventSlashBubbling } from './usePreventSlashBubbling'

const lightTheme = vsLight
const darkTheme = vsDark

type CodeEditorProps = {
  code: string
  disabled?: boolean
  language?: string
  onChange?: (code: string) => void
  style?: CSSProperties
  className?: string
  shareWithPreview?: boolean
}

export const CodeEditor = (props: CodeEditorProps) => {
  // eslint-disable-next-line no-unused-vars
  const {
    style,
    onChange,
    disabled,
    code: codeViaProps,
    language = 'markup',
    className,
    shareWithPreview,
  } = props
  const [code, setCode] = useState(formatCode(codeViaProps, language) || '')
  const ref = useRef()
  const { hasDarkMode } = useModeExtractor(ref)

  const selectedTheme = hasDarkMode ? darkTheme : lightTheme

  const {
    setBgc,
    setCompactMode,
    setDarkMode,
    editorMode,
    renderMultipleComponents,
  } = useCodeContext()
  usePreventSlashBubbling(ref)

  const handleCodeChange = (code: string) => {
    if (onChange) {
      onChange(code)
    }
  }

  const onFormatCode = () => {
    setCode(formatCode(code, language))
  }

  const onResetCode = () => {
    const resetCode = formatCode(codeViaProps, language) || ''
    setCode(resetCode)
    handleCodeChange(resetCode)
    setBgc(undefined)
    setCompactMode(undefined)
    setDarkMode(undefined)
  }

  const updateContent = (newCode: string) => {
    setCode(newCode)
    handleCodeChange(newCode)
  }

  const highlightCode = (codeToHighlight: string) => (
    <Highlight
      Prism={Prism}
      code={codeToHighlight}
      theme={selectedTheme}
      language={(props.language as Language) || 'markup'}
    >
      {({ tokens, getLineProps, getTokenProps }) => (
        <Fragment>
          {tokens.slice(0, tokens.length).map((line, i) => (
            // eslint-disable-next-line react/jsx-key
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                // eslint-disable-next-line react/jsx-key
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </Fragment>
      )}
    </Highlight>
  )

  if (editorMode === EDITOR_MODE.PREVIEW_ONLY) return null
  return (
    <Box
      ref={ref}
      className={classNames([codeEditor], {
        [className]: !!className,
      })}
    >
      <CodeOptions
        content={code}
        onFormat={onFormatCode}
        onReset={onResetCode}
        language={language}
        shareWithPreview={shareWithPreview}
        renderMultipleComponents={renderMultipleComponents}
      />
      <Box className={codeEditorCode}>
        <Editor
          value={code}
          padding={tokensRaw.SwanSemSpace5}
          highlight={highlightCode}
          onValueChange={updateContent}
          disabled={disabled}
          style={{
            whiteSpace: 'pre',
            fontFamily: "'Fira Code', monospace",
            ...selectedTheme.plain,
            ...style,
            backgroundColor: 'transparent',
          }}
        />
      </Box>
    </Box>
  )
}
