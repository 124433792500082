import { Span, StyleFontSize, SwanStyleProps, Typography } from '@vp/swan'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { ComponentPropsWithRef, PropsWithChildren, useRef } from 'react'
import { FiLink2 } from 'react-icons/fi'
import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard.hook'
import { heading, link, linkCopied } from './heading.module.scss'

export type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

const headingToFontSizeMapping: Record<string, StyleFontSize> = {
  h1: 'x3large',
  h2: 'x2large',
  h3: 'large',
  h4: 'standard',
  h5: 'small',
  h6: 'xsmall',
}

export const Heading = ({
  element,
  headingId,
  headingLink,
  children,
  className,
  ...props
}: SwanStyleProps &
  ComponentPropsWithRef<HeadingElement> &
  PropsWithChildren<{
    element: HeadingElement
    headingId?: string
    headingLink?: string
  }>) => {
  const { copyStatus, copyToClipboard } = useCopyToClipboard()
  const ref = useRef(null)
  return (
    <Typography
      {...props}
      className={classNames(className, heading)}
      component={element}
      fontSize={headingToFontSizeMapping[element]}
      id={headingId}
      __dangerouslySuppressWarning
    >
      {children}
      {!!headingId && (
        <Link
          ref={ref}
          className={link}
          to={headingLink}
          onClick={() => copyToClipboard(ref.current?.href)}
        >
          <FiLink2 />
          {copyStatus === 'DONE' && (
            <Span fontSize="xsmall" ml={1} className={linkCopied}>
              Copied!
            </Span>
          )}
        </Link>
      )}
    </Typography>
  )
}
