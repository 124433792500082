import {
  ErrorBoundary,
  Span,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableScrollContainer,
  Typography,
} from '@vp/swan'
import data from '@vp/swan/docs/component-props.json'
import { Link } from 'gatsby'
import { Maybe, SanityPropsBlock } from '../../../../types/gatsby-graphql'
import { SlugPrefix } from '../../../constants/slug-prefix'
import {
  corePropsNote,
  descText,
  tableHeading,
} from './schema-props.module.scss'
import { getDeprecatedString } from './utils'

type IPropsInfo = Record<
  string,
  {
    defaultValue: string
    description: string
    required: boolean
    type: string
  }
>

export type IComponentPropsInfo = {
  description: string
  value: string
  props: IPropsInfo
}

type IPropsData = {
  prop: string
  type: string
  description: string
  required: string
  default: string
}

const CELL_NAME = ['prop', 'type', 'required', 'description', 'default']

const getPropData = (data: IComponentPropsInfo): IPropsData[] => {
  const { props } = data ?? {}
  if (props) {
    const propNames = Object.keys(props)
    return propNames.reduce((arr, name) => {
      const { required, defaultValue, type, description } = props[name]
      return arr.concat({
        prop: name,
        type,
        description: description || '-',
        required: required.toString(),
        default: defaultValue ?? 'null',
      })
    }, [])
  }
  return []
}

export const SchemaProps = ({
  node: config,
}: {
  node?: Maybe<SanityPropsBlock>
}) => {
  const { componentId, htmlTag } = config

  const getRenderContext = (key: string, data: string) => {
    if (key === 'default') {
      return <pre>{data}</pre>
    }
    if (key === 'type') {
      return <code>{data}</code>
    }
    if (key === 'description') {
      const [desc, deprecatedDesc] = getDeprecatedString(data)
      return (
        <>
          {deprecatedDesc ? (
            <Typography>
              ⚠️ Deprecated -{' '}
              <Span>{deprecatedDesc.replace('@deprecated', ' ')}</Span>
            </Typography>
          ) : null}
          <Typography className={descText}>{desc}</Typography>
        </>
      )
    }

    return data
  }

  const htmlTagText = htmlTag ? (
    <>
      This component is implemented using the <code>{htmlTag}</code> as the root
      element. You can utilize the native attributes supported by{' '}
      <code>{htmlTag}</code> tag.
    </>
  ) : (
    ''
  )

  const propsData = componentId ? getPropData(data[componentId]) : []
  return (
    <ErrorBoundary>
      <Typography fontSize="large" fontWeight="bold" mb={'4'} mt={'7'}>
        {componentId}
      </Typography>
      <Typography mt={'4'}>
        {htmlTagText} The <code>ref</code> is directly assigned to the root
        element, allowing you to access and manipulate it as needed.
      </Typography>
      <Typography className={corePropsNote} mt={'4'}>
        See{' '}
        <Link to={`${SlugPrefix.foundationPage}/core-props/`}>core props</Link>{' '}
        for additional props that can be applied to this component.
      </Typography>
      {propsData.length ? (
        <TableScrollContainer mt={'7'}>
          <Table textAlign="left">
            <TableHead>
              <TableRow>
                {CELL_NAME.map(title => (
                  <TableCell
                    className={tableHeading}
                    key={title}
                    textAlign="left"
                  >
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {propsData.map(obj => (
                <TableRow key={obj.prop}>
                  {CELL_NAME.map(key => {
                    return (
                      <TableCell key={obj[key]} textAlign="left">
                        {getRenderContext(key, obj[key])}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableScrollContainer>
      ) : null}
    </ErrorBoundary>
  )
}
