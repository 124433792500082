import { StandardForm, TextInput, TextInputWithButtonInset } from '@vp/swan'
import { useEffect, useRef } from 'react'
import { VoiceSearch, connectSearchBox } from 'react-instantsearch-dom'
import { container, input } from './search-box.module.scss'

export const SearchBox = connectSearchBox(({ refine, currentRefinement }) => {
  const inputRef = useRef<HTMLInputElement>()
  useEffect(() => {
    inputRef.current.focus()
  }, [])
  return (
    <StandardForm
      onSubmit={evt => evt.preventDefault()}
      autoComplete="off"
      className={container}
    >
      <TextInputWithButtonInset>
        <TextInput
          type="search"
          autoComplete="off"
          placeholder="Search..."
          aria-label="Search"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          ref={inputRef}
          className={input}
        />
        <VoiceSearch />
      </TextInputWithButtonInset>
    </StandardForm>
  )
})
