import slugify from 'slugify'
import { ContentBlocks } from '.'
import { SanityBlock } from '../../../../types/gatsby-graphql'
import { HeadingsObjectValue, TocObjectValue } from './content.context'

type HeadingsMap = Map<string, HeadingsObjectValue>
type TocMap = Map<string, TocObjectValue>

type ValidHeadings = Set<string>
const defaultValidHeadings = new Set(['h2', 'h3', 'h4', 'h5', 'h6'])

function isValidHeadingBlock(
  block: SanityBlock,
  validHeadings: ValidHeadings = defaultValidHeadings,
) {
  return block._type === 'block' && validHeadings.has(block?.style)
}

function createSubToc(
  prevH2HeadingData: TocObjectValue,
  h3TocData: HeadingsObjectValue,
) {
  return {
    ...prevH2HeadingData,
    subToc: prevH2HeadingData.subToc.concat(h3TocData),
  }
}

export function getRightMenuToc(contentBlocks: ContentBlocks) {
  const headingToc: TocMap = new Map()
  const validH2Heading = new Set(['h2'])
  const validH3Heading = new Set(['h3'])
  let prevH2HeadingKey = ''

  contentBlocks?.forEach(block => {
    const tocH2 = getTocFields(block, validH2Heading)
    const tocH3 = getTocFields(block, validH3Heading)

    if (tocH2) {
      const toc = { ...tocH2, subToc: [] }
      headingToc.set(block._key, toc)
      prevH2HeadingKey = block._key
    } else if (tocH3) {
      const prevH2HeadingData = headingToc.get(prevH2HeadingKey)
      if (tocH3 && prevH2HeadingData) {
        headingToc.set(prevH2HeadingKey, createSubToc(prevH2HeadingData, tocH3))
      }
    }
  })

  const toc = {}
  headingToc.forEach((v, k) => {
    toc[k] = v
  })

  return toc
}

function getTocFields(block: SanityBlock, validHeadings: ValidHeadings) {
  if (block && isValidHeadingBlock(block, validHeadings)) {
    const text = block.children
      ?.map(child => child?.text)
      .filter(Boolean)
      .toString()
    if (block._key && text) {
      return {
        slug: slugify(text),
        text,
      }
    }
  }
  return null
}

export function getToc(
  contentBlocks?: ContentBlocks,
  validHeadings: ValidHeadings = defaultValidHeadings,
) {
  const tocMap: HeadingsMap = new Map()
  contentBlocks?.forEach(block => {
    const toc = getTocFields(block, validHeadings)
    if (toc) {
      tocMap.set(block._key, toc)
    }
  })
  return tocMap
}
