import { Box, Card, ErrorBoundary, Typography } from '@vp/swan'
import classNames from 'classnames'
import { ReactElement } from 'react'
import { Heading, SchemaContent, SchemaImageBlock } from '../..'
import {
  Maybe,
  SanityLink,
  SanitySwanCard,
} from '../../../../types/gatsby-graphql'
import { SchemaMarkdown } from '../../schema-markdown/schema-markdown'
import { SchemaLinkForContent } from '../schema-link-for-content/schema-link-for-content'
import {
  cardContainer,
  cardImageBottom,
  cardImageCenter,
  cardImageLeft,
  cardImageRight,
  cardImageTop,
  cardTextAlignCenter,
  cardTextAlignJustify,
  cardTextAlignRight,
  description,
  fullBleedImage,
  image,
  imageWrap,
  linkTag,
  spacer,
  title,
} from './schema-swan-card.module.scss'
import { getJSON } from './schema-swan-card.utils'

const components = {
  p: ({ node, ...props }) => (
    <Typography mt={'2'} fontSize="small" {...props} />
  ),
}

function getCardClassNames(config: Maybe<SanitySwanCard>): string {
  return classNames(cardContainer, {
    [cardImageTop]: config?.variant === 'view:image-top',
    [cardImageCenter]: config?.variant === 'view:image-center',
    [cardImageBottom]: config?.variant === 'view:image-bottom',
    [cardImageLeft]: config?.variant === 'view:image-left',
    [cardImageRight]: config?.variant === 'view:image-right',
  })
}

function getTextClassName(side: string): Record<string, boolean> {
  return {
    [cardTextAlignRight]: side === 'right',
    [cardTextAlignCenter]: side === 'center',
    [cardTextAlignJustify]: side === 'justify',
  }
}

// wrapper link tag
function wrapWithLink(
  markKey?: string,
  link?: SanityLink,
  node?: ReactElement,
) {
  if (!link || !markKey) {
    return node
  }
  return (
    <SchemaLinkForContent
      markKey={markKey}
      mark={link}
      enforceLink
      noIconAddons
      className={classNames(linkTag)}
    >
      {node}
    </SchemaLinkForContent>
  )
}

export const SchemaSwanCard = ({
  node: config,
  fallbackKey,
  insideGrid,
}: {
  node?: Maybe<SanitySwanCard>
  fallbackKey?: string
  insideGrid?: boolean
}) => {
  const _key = `swan_card_${config._key || fallbackKey}`
  const overrideConfig = getJSON(config.jsonValueRaw)
  const bordered = config.cardType === 'bordered'

  const configTitle = config?.title ? (
    <Heading
      element="h4"
      className={classNames(title, getTextClassName(config.titleAlign))}
    >
      {config?.title}
    </Heading>
  ) : null

  // Title and description
  const renderTitleDescription = (
    <>
      {wrapWithLink(_key, config.link, configTitle)}
      <div
        className={classNames(description, getTextClassName(config.textAlign))}
      >
        {config?.description && (
          <SchemaMarkdown node={config?.description} components={components} />
        )}
        {config?.content && (
          <SchemaContent
            contentBlocks={config?.content}
            allContentLinks={undefined}
          />
        )}
      </div>
    </>
  )

  // card
  const swanCardElem = (
    <Box className={`${getCardClassNames(config)}`}>
      <Box
        className={classNames(imageWrap, {
          [spacer]: !config.fullImage,
          [fullBleedImage]: config.fullImage,
        })}
        {...(overrideConfig.cardImage || {})}
      >
        <SchemaImageBlock
          className={classNames(image)}
          node={config?.imageBlock}
        />
      </Box>
      <Box className={classNames(spacer)} {...(overrideConfig.cardText || {})}>
        {renderTitleDescription}
      </Box>
    </Box>
  )

  return (
    <ErrorBoundary>
      <Card
        fullBleed
        skin={config.link?.linkType ? 'link' : ''}
        bordered={bordered}
        evenHeight={insideGrid}
        overflow={config.fullImage ? 'hidden' : undefined}
        {...(overrideConfig.container || {})}
      >
        {wrapWithLink(_key, config.link, swanCardElem)}
      </Card>
    </ErrorBoundary>
  )
}
